/* react_frontend/src/pages/Store/ShipmentPage.css */
.aerial-dashboard {
    position: relative;
    background: linear-gradient(135deg, #f7fafc 0%, #e2e8f0 100%);
    min-height: 100vh;
    padding: 0;
    overflow-x: hidden;
}

.control-tower-header {
    background: linear-gradient(90deg, #d1d5db, #e5e7eb);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 1.5rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #e2e8f0;
    overflow: scroll;
    border-radius: 50px;
    flex-wrap: wrap;
    text-align: center;
}

.control-tower-header h2 {
    color: #1a202c;
    font-size: 2rem;
    font-weight: 700;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.control-buttons {
    display: flex;
    gap: 1rem;
}

.control-btn {
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    border: none;
    border-radius: 20px;
    padding: 0.75rem 1.5rem;
    color: #ffffff;
    font-weight: 600;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.control-btn:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(76, 81, 191, 0.3);
}

.refresh-btn::before {
    content: "↻ ";
}

.export-btn::before {
    content: "↓ ";
}

.sky-loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    color: #4c51bf;
    font-size: 1.5rem;
}

.radar-spinner {
    width: 50px;
    height: 50px;
    border: 4px solid #e2e8f0;
    border-top: 4px solid #4c51bf;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.flight-ticker {
    display: flex;
    gap: 1rem;
    overflow-x: auto;
    padding: 2rem;
}

.ticker-card {
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    min-width: 200px;
    text-align: center;
    transition: transform 0.2s ease;
}

.ticker-card:hover {
    transform: translateY(-5px);
}

.ticker-card h3 {
    color: #4c51bf;
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
}

.ticker-card .value {
    font-size: 1.75rem;
    font-weight: 700;
    color: #1a202c;
}

.ticker-card .count {
    color: #718096;
    font-size: 1rem;
}

.total-card {
    background: linear-gradient(135deg, #4c51bf, #7f9cf5);
    color: #ffffff;
}

.total-card h3,
.total-card .value,
.total-card .count {
    color: #ffffff;
}

.glass-table {
    margin: 0 2rem 2rem;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 16px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.table-header {
    display: grid;
    grid-template-columns: 1fr 2fr;
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    color: #ffffff;
    padding: 1rem 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
}

.table-row {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #e2e8f0;
    transition: background 0.2s ease;
}

.table-row:hover {
    background: rgba(241, 245, 249, 0.5);
}

.order-cell,
.status-cell {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow-x: scroll;
}

.order-number {
    font-weight: 700;
    color: #1a202c;
}

.payment-type,
.days,
.remark,
.comment {
    color: #718096;
    font-size: 0.9rem;
}

.tracking-link {
    color: #4c51bf;
    text-decoration: none;
}

.tracking-link:hover {
    text-decoration: underline;
}

.view-blip {
    background: #4c51bf;
    border: none;
    border-radius: 20%;
    width: 60px;
    height: 40px;
    color: #ffffff;
    font-size: 0.9rem;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.view-blip:hover {
    transform: scale(1.1);
    box-shadow: 0 4px 8px rgba(76, 81, 191, 0.4);
}

.status-tag {
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-weight: 500;
    text-align: center;
}

.status-tag.fulfilled {
    background: #e6fffa;
    color: #2c7a7b;
}

.status-tag.pending {
    background: #fefcbf;
    color: #975a16;
}

.radar-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
}

.radar-modal.active {
    opacity: 1;
    visibility: visible;
}

.radar-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}

.radar-content {
    position: relative;
    background: #ffffff;
    border-radius: 10px;
    width: 80vw;
    max-width: 800px;
    height: 80vh;
    max-height: 800px;
    padding: 3rem;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
    overflow-y: auto;
    transform: scale(0.8);
    animation: radarPop 0.5s ease forwards;
}

@keyframes radarPop {
    to {
        transform: scale(1);
    }
}

.radar-sweep {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(76, 81, 191, 0.2) 0%, transparent 70%);
    border-radius: 50%;
    animation: radarSweep 4s linear infinite;
    pointer-events: none;
}

@keyframes radarSweep {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: #dc3545;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: #ffffff;
    font-size: 1.2rem;
    cursor: pointer;
}

.radar-title {
    text-align: center;
    color: #4c51bf;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
    text-shadow: 0 2px 4px rgba(76, 81, 191, 0.1);
}

.radar-body {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.flight-section h3 {
    color: #4c51bf;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.info-panel,
.comment-feed {
    background: rgba(241, 245, 249, 0.5);
    border-radius: 12px;
    padding: 1.5rem;
}

.info-panel p {
    margin: 0.5rem 0;
    color: #1a202c;
}

.path-item {
    background: #ffffff;
    border-radius: 12px;
    margin-bottom: 0.5rem;
}

.comment-bubble {
    background: #ffffff;
    border-radius: 12px;
    padding: 1rem;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.comment-bubble span {
    color: #718096;
    font-size: 0.9rem;
}

.comment-input textarea {
    border: 1px solid #e2e8f0;
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 1rem;
    resize: none;
}

.send-btn {
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    border: none;
    border-radius: 20px;
    padding: 0.75rem 1.5rem;
    color: #ffffff;
    font-weight: 600;
}

@media (max-width: 768px) {
    .table-header {
        grid-template-columns: 1fr;
    }

    .table-row {
        grid-template-columns: 1fr;
    }
}

/* react_frontend/src/pages/SettingsPage.css */
/* ... (existing styles remain unchanged) */

/* Crew Forge Styles */
.crew-forge .forge-portal {
    max-width: 500px;
}

.crew-forge input,
.crew-forge select {
    margin-bottom: 1rem;
}

.crew-list {
    margin-bottom: 2rem;
}

.crew-list h5 {
    color: #00d4ff;
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.crew-member {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(65, 90, 119, 0.8);
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 0.5rem;
}

.crew-member span {
    color: #e0e1dd;
    font-weight: 500;
}

.crew-role {
    color: #ffbe0b;
    font-weight: 600;
}

.filter-section {
    padding: 1rem;
    background: #f7fafc;
    border-radius: 8px;
    margin: 1.5rem;
    border: 1px solid #edf2f7;
    transition: height 0.3s ease;
}

.filter-section .filter-header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
}

.filter-section .filter-header h3 {
    margin: 0;
    font-size: 1.2rem;
    color: #4a5568;
}

.filter-section .filter-header span {
    font-size: 1rem;
    color: #4a5568;
}

.filter-section .filter-content {
    display: none;
    /* Hidden by default */
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.filter-section.open .filter-content {
    display: flex;
    /* Show when open */
}

.filter-container {
    display: flex;
    align-items: flex-end;
    gap: 1rem;
    flex-wrap: wrap;
}

.filter-section .filter-group {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    margin-bottom: 1rem;
    min-width: 160px;
    max-width: 220px;
    margin: 1rem;
    align-items: center;
}

.filter-group {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    margin-bottom: 1rem;
    min-width: 160px;
    max-width: 220px;
    margin: 1rem;
    align-items: center;
}

.filter-group label {
    font-size: 0.85rem;
    color: #4a5568;
    font-weight: 500;
    margin-bottom: 0.3rem;
    display: block;
}

.filter-group select,
.filter-group input[type="date"] {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 0.9rem;
    background: #fff;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.filter-group select:hover,
.filter-item input[type="date"]:hover {
    border-color: #cbd5e0;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.1);
}

.filter-group select:focus,
.filter-group input[type="date"]:focus {
    border-color: #4299e1;
    outline: none;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.2);
}

.date-range-inputs {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.date-range-inputs span {
    font-size: 0.9rem;
    color: #718096;
}

.date-range-inputs input[type="date"] {
    flex: 1;
}

.apply-filter-btn {
    background-color: #4299e1;
    border-color: #4299e1;
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    border-radius: 6px;
    height: 36px;
    transition: background-color 0.2s ease;
}

.apply-filter-btn:hover {
    background-color: #3182ce;
    border-color: #3182ce;
}

/* Mobile Optimization */
@media (max-width: 768px) {
    .filter-container {
        flex-direction: column;
        align-items: stretch;
        gap: 1rem;
    }

    .filter-group {
        max-width: 100%;
        width: 100%;
    }

    .date-range-inputs {
        flex-direction: column;
        gap: 0.8rem;
    }

    .date-range-inputs span {
        display: none;
    }

    .apply-filter-btn {
        width: 100%;
    }
}

/* Ensure table remains intact */
.shipment-table th,
.shipment-table td {
    padding: 0.75rem;
    font-size: 0.9rem;
    vertical-align: middle;
}