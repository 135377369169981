/* react_frontend/src/pages/InventoryPage.css */

.alchemist-vault {
    position: relative;
    background: linear-gradient(135deg, #f7fafc 0%, #e2e8f0 100%);
    /* ShipmentPage main bg */
    min-height: 100vh;
    padding: 0;
    overflow-x: hidden;
}

.vault-header {
    background: linear-gradient(90deg, #d1d5db, #e5e7eb);
    /* ShipmentPage header bg */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    /* ShipmentPage shadow */
    padding: 1.5rem 2rem;
    text-align: center;
    border-radius: 40px;
}

.vault-header h3 {
    color: #1a202c;
    /* ShipmentPage header text */
    font-size: 2rem;
    font-weight: 700;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    /* Adjusted for ShipmentPage */
    animation: glowPulse 2s infinite alternate;
}

@keyframes glowPulse {
    0% {
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
        /* ShipmentPage subtle shadow */
    }

    100% {
        text-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
}

.alchemist-compass {
    margin: 2rem auto;
    max-width: 300px;
    position: relative;
    text-align: center;
}

.alchemist-compass label {
    color: #1a202c;
    /* ShipmentPage header text */
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    display: block;
}

.alchemist-compass select {
    width: 100%;
    padding: 0.75rem;
    border: 2px solid #e2e8f0;
    /* ShipmentPage border */
    border-radius: 10px;
    background: #ffffff;
    /* ShipmentPage card bg */
    font-size: 1rem;
    color: #1a202c;
    /* ShipmentPage text */
    transition: border-color 0.2s ease;
}

.alchemist-compass select:focus {
    border-color: #4c51bf;
    /* ShipmentPage accent */
    outline: none;
    box-shadow: 0 0 0 4px rgba(76, 81, 191, 0.2);
    /* Adjusted for ShipmentPage */
}

.alchemist-compass::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 12px;
    height: 12px;
    background: #4c51bf;
    /* ShipmentPage accent */
    border-radius: 50%;
    animation: compassSpin 3s linear infinite;
    pointer-events: none;
}

@keyframes compassSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.vault-loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    /* ShipmentPage loading bg */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    color: #4c51bf;
    /* ShipmentPage accent */
    font-size: 1.5rem;
}

.cauldron-spinner {
    width: 50px;
    height: 50px;
    background: radial-gradient(circle, #4c51bf 20%, transparent 70%);
    /* ShipmentPage accent */
    border-radius: 50%;
    animation: bubble 1.5s ease infinite;
}

@keyframes bubble {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.2);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.vault-error {
    text-align: center;
    color: #dc3545;
    /* ShipmentPage close button (error) */
    font-size: 1.25rem;
    margin: 2rem;
}

.vault-sections {
    padding: 0 10px;
}

.relic-vault,
.essence-vault {
    margin: 0 0 2rem;
    background: rgba(255, 255, 255, 0.9);
    /* ShipmentPage card bg */
    border-radius: 12px;
    overflow: hidden;
    transition: all 0.3s ease;
}

.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    background: linear-gradient(90deg, #d1d5db, #e5e7eb);
    /* ShipmentPage header bg */
    cursor: pointer;
    user-select: none;
}

.section-header h4 {
    color: #1a202c;
    /* ShipmentPage header text */
    font-size: 1.75rem;
    font-weight: 600;
    margin: 0;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    /* ShipmentPage shadow */
}

.toggle-icon {
    color: #4c51bf;
    /* ShipmentPage accent */
    font-size: 1.5rem;
    transition: transform 0.3s ease;
}

.relic-vault.active .toggle-icon,
.essence-vault.active .toggle-icon {
    transform: rotate(180deg);
}

.relic-vault:not(.active) .relic-showcase,
.essence-vault:not(.active) .essence-cauldrons,
.relic-vault:not(.active) .vault-runes,
.essence-vault:not(.active) .vault-runes,
.relic-vault:not(.active) .section-controls,
.essence-vault:not(.active) .section-controls {
    display: none;
}

.section-controls {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem 2rem;
    background: rgba(241, 245, 249, 0.5);
    /* ShipmentPage hover bg */
    flex-wrap: wrap;
}

.sort-controls {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}

.sort-controls label {
    color: #1a202c;
    /* ShipmentPage header text */
    font-weight: 600;
}

.sort-controls select {
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    /* ShipmentPage border */
    border-radius: 5px;
    background: #ffffff;
    /* ShipmentPage card bg */
    font-size: 1rem;
    color: #1a202c;
    /* ShipmentPage text */
    transition: border-color 0.2s ease;
}

.sort-controls select:focus {
    border-color: #4c51bf;
    /* ShipmentPage accent */
    outline: none;
}

.sort-controls input[type="checkbox"] {
    margin: 0;
    accent-color: #4c51bf;
    /* ShipmentPage accent */
}

.relic-showcase,
.essence-cauldrons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding-block: 2rem;
    padding-inline: 0px;
}

.relic-card,
.cauldron {
    background: #ffffff;
    /* ShipmentPage card bg */
    border-radius: 12px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
    /* ShipmentPage shadow */
    padding: 1.5rem;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.relic-card:hover,
.cauldron:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(76, 81, 191, 0.3);
    /* ShipmentPage hover shadow */
}

.relic-sku,
.cauldron-name {
    font-weight: 700;
    color: #1a202c;
    /* ShipmentPage header text */
    font-size: 1.25rem;
}

.relic-name,
.cauldron-unit,
.cauldron-properties {
    color: #718096;
    /* ShipmentPage subtext */
    margin: 0.5rem 0;
}

.relic-locations {
    margin: 1rem 0;
}

.location-orb {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin: 0.5rem 0;
}

.location-orb span:first-child {
    color: #4c51bf;
    /* ShipmentPage accent */
    font-weight: 600;
}

.relic-quantity,
.cauldron-quantity {
    display: flex;
    align-items: center;
    gap: 1rem;
    position: relative;
    margin: 1rem 0;
}

.dial-btn {
    background: #4c51bf;
    /* ShipmentPage accent */
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    color: #ffffff;
    /* ShipmentPage button text */
    font-size: 1rem;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.dial-btn:hover {
    transform: scale(1.1);
}

.quantity-orb {
    background: radial-gradient(circle, #b443ba 20%, transparent 70%);
    /* ShipmentPage fulfilled color */
    padding: 0.5rem 1rem;
    border-radius: 20px;
    color: #ffffff;
    /* ShipmentPage button text */
    font-weight: 600;
}

.adjust-toggle {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    transition: transform 0.2s ease;
}

.adjust-toggle:hover {
    transform: scale(1.1);
}

.adjust-toggle svg {
    fill: #4c51bf;
    /* ShipmentPage accent */
}

.adjust-input {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: #ffffff;
    /* ShipmentPage card bg */
    border: 1px solid #e2e8f0;
    /* ShipmentPage border */
    border-radius: 10px;
    padding: 0.5rem;
    display: flex;
    gap: 0.5rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    /* ShipmentPage shadow */
    z-index: 10;
}

.adjust-input input {
    width: 80px;
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    /* ShipmentPage border */
    border-radius: 5px;
    font-size: 1rem;
    color: #1a202c;
    /* ShipmentPage text */
}

.adjust-btn {
    background: #4c51bf;
    /* ShipmentPage accent */
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    color: #ffffff;
    /* ShipmentPage button text */
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.adjust-btn:hover {
    transform: scale(1.05);
}

.crucible-btn {
    background: #dc3545;
    /* ShipmentPage close button */
    border: none;
    border-radius: 20px;
    padding: 0.5rem 1rem;
    color: #ffffff;
    /* ShipmentPage button text */
    font-weight: 600;
    transition: transform 0.2s ease;
}

.crucible-btn:hover {
    transform: scale(1.05);
}

.forge-btn {
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    /* ShipmentPage button bg */
    border: none;
    border-radius: 20px;
    padding: 0.75rem 1.5rem;
    color: #ffffff;
    /* ShipmentPage button text */
    font-weight: 600;
    transition: transform 0.2s ease;
}

.forge-btn:hover {
    transform: scale(1.05);
}

.sync-btn {
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    /* ShipmentPage button bg */
    border: none;
    border-radius: 20px;
    padding: 0.75rem 1.5rem;
    color: #ffffff;
    /* ShipmentPage button text */
    font-weight: 600;
    transition: transform 0.2s ease;
}

.sync-btn:hover {
    transform: scale(1.05);
}

.sync-btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.vault-runes {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin: 2rem 0;
    align-items: center;
}

.rune-btn {
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    /* ShipmentPage button bg */
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    color: #ffffff;
    /* ShipmentPage button text */
    font-weight: 600;
    transition: opacity 0.2s ease, transform 0.2s ease;
}

.rune-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.rune-btn:not(:disabled):hover {
    transform: scale(1.1);
    animation: runePulse 1s infinite alternate;
}

@keyframes runePulse {
    0% {
        box-shadow: 0 0 0 0 rgba(76, 81, 191, 0.7);
        /* ShipmentPage blue shadow */
    }

    100% {
        box-shadow: 0 0 0 10px rgba(76, 81, 191, 0);
    }
}

.rune-page {
    color: #4c51bf;
    /* ShipmentPage accent */
    font-size: 1.25rem;
    font-weight: 600;
}

.vault-success {
    text-align: center;
    color: #2c7a7b;
    /* ShipmentPage fulfilled text */
    font-size: 1.25rem;
    margin: 1rem 0;
}

.relic-total {
    margin-top: 1rem;
    color: #1a202c;
    /* ShipmentPage header text */
    font-weight: 600;
}

.total-glow {
    color: #2c7a7b;
    /* ShipmentPage fulfilled text */
    font-weight: 700;
}

.grimoire-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    max-height: 100vh;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease;
}

.grimoire-modal.active {
    opacity: 1;
    visibility: visible;
}

.grimoire-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    /* ShipmentPage overlay (adjusted) */
}

.grimoire-content {
    background: #ffffff;
    /* ShipmentPage card bg */
    border-radius: 20px;
    padding: 2rem;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
    /* ShipmentPage shadow */
    transform: rotateX(-90deg);
    animation: pageFlip 0.5s ease forwards;
    max-height: 90vh;
    overflow: scroll;
}

@keyframes pageFlip {
    to {
        transform: rotateX(0deg);
    }
}

.grimoire-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: #dc3545;
    /* ShipmentPage close button */
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: #ffffff;
    /* ShipmentPage button text */
    font-size: 1.2rem;
    cursor: pointer;
}

.grimoire-content h3 {
    color: #4c51bf;
    /* ShipmentPage accent */
    font-size: 1.75rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 1.5rem;
}

.grimoire-page {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.grimoire-page label {
    color: #1a202c;
    /* ShipmentPage header text */
    font-weight: 600;
}

.grimoire-page input,
.grimoire-page textarea,
.grimoire-page select {
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    /* ShipmentPage border */
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8);
    /* ShipmentPage bg */
    transition: border-color 0.2s ease;
    color: #1a202c;
    /* ShipmentPage text */
}

.grimoire-page input:focus,
.grimoire-page textarea:focus,
.grimoire-page select:focus {
    border-color: #4c51bf;
    /* ShipmentPage accent */
    outline: none;
}

.inscribe-btn {
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    /* ShipmentPage button bg */
    border: none;
    border-radius: 20px;
    padding: 0.75rem 1.5rem;
    color: #ffffff;
    /* ShipmentPage button text */
    font-weight: 600;
    align-self: center;
    margin-top: 1rem;
    transition: transform 0.2s ease;
}

.inscribe-btn:hover {
    transform: scale(1.05);
}

/* react_frontend/src/pages/InventoryPage.css */

/* Ensure buttons in finished goods section align with the design */
.relic-quantity .adjust-btn {
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    /* Matches ShipmentPage button bg */
    border: none;
    border-radius: 20px;
    padding: 0.5rem 1rem;
    color: #ffffff;
    /* ShipmentPage button text */
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.relic-quantity .adjust-btn:hover {
    transform: scale(1.05);
}

/* Adjust spacing for category in raw materials */
.cauldron-name {
    font-weight: 700;
    color: #1a202c;
    /* ShipmentPage header text */
    font-size: 1.25rem;
    margin-bottom: 0.5rem;
    /* Added for better spacing with category */
}

/* Ensure transferred status fits the orb design */
.relic-quantity .quantity-orb {
    background: radial-gradient(circle, #2c7a7b 20%, transparent 70%);
    /* Matches fulfilled color for transferred status */
    padding: 0.5rem 1rem;
    border-radius: 20px;
    color: #ffffff;
    /* ShipmentPage button text */
    font-weight: 600;
}

/* react_frontend/src/pages/InventoryPage.css */

/* Subcategory Section */
.subcategory-section {
    margin-bottom: 1rem;
    background: rgba(241, 245, 249, 0.8);
    /* Slightly lighter than section-controls bg */
    border-radius: 8px;
    overflow: hidden;
}

.subcategory-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    background: linear-gradient(90deg, #e2e8f0, #edf2f7);
    /* Subtle gradient */
    cursor: pointer;
    user-select: none;
}

.subcategory-header h5 {
    color: #1a202c;
    /* ShipmentPage header text */
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.subcategory-section:not(.active) .subcategory-content {
    display: none;
}

.subcategory-content {
    padding: 1rem 1.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
}

/* react_frontend/src/pages/InventoryPage.css */

/* Subcategory Section */
.subcategory-section {
    margin-bottom: 1rem;
    background: rgba(241, 245, 249, 0.8);
    /* Slightly lighter than section-controls bg */
    border-radius: 8px;
    overflow: hidden;
}

.subcategory-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    background: linear-gradient(90deg, #e2e8f0, #edf2f7);
    /* Subtle gradient */
    cursor: pointer;
    user-select: none;
}

.subcategory-header h5 {
    color: #1a202c;
    /* ShipmentPage header text */
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.subcategory-section:not(.active) .subcategory-content {
    display: none;
    /* Hide content when not active */
}

.subcategory-content {
    padding: 1rem 1.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.5rem;
}

.toggle-icon {
    color: #4c51bf;
    /* ShipmentPage accent */
    font-size: 1.5rem;
    transition: transform 0.3s ease;
}

.subcategory-section.active .toggle-icon {
    transform: rotate(180deg);
    /* Rotate when active */
}

.duplicate-btn {
    background: #68d391;
    /* Greenish for duplication */
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 0.5rem;
    font-size: 0.9rem;
    transition: background 0.3s ease;
}

.duplicate-btn:hover {
    background: #48bb78;
}

/* react_frontend/src/pages/InventoryPage.css */

/* Existing styles remain unchanged until .assignment-vault */

.assignment-vault {
    margin-top: 20px;
}

.assignment-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 2rem;
    padding: 2rem 10px;
}

.assignment-card {
    background: #ffffff;
    /* ShipmentPage card bg */
    border-radius: 12px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
    /* ShipmentPage shadow */
    overflow: hidden;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.assignment-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(76, 81, 191, 0.3);
    /* ShipmentPage hover shadow */
}

.assignment-header {
    background: linear-gradient(90deg, #d1d5db, #e5e7eb);
    /* ShipmentPage header bg */
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e2e8f0;
    /* ShipmentPage border */
}

.assignment-header h4 {
    color: #1a202c;
    /* ShipmentPage header text */
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.status-badge {
    padding: 0.5rem 1rem;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: 600;
    text-transform: capitalize;
    color: #ffffff;
    /* ShipmentPage button text */
}

.status-badge.pending {
    background: radial-gradient(circle, #f6ad55 20%, #ed8936 70%);
    color: #ffffff;
    /* Orange for pending */
}

.status-badge.completed {
    background: radial-gradient(circle, #5abbbc 20%, #2c7a7b 70%);
    color: #ffffff;
    /* ShipmentPage fulfilled color */
}

.status-badge.cancelled {
    background: radial-gradient(circle, #ff8c97 20%, #dc3545 70%);
    color: #ffffff;
    /* ShipmentPage close button color */
}

.assignment-body {
    padding: 1.5rem;
}

.assignment-info p {
    margin: 0.75rem 0;
    color: #718096;
    /* ShipmentPage subtext */
}

.assignment-info p strong {
    color: #1a202c;
    /* ShipmentPage header text */
    font-weight: 600;
    margin-right: 0.5rem;
}

.assignment-footer {
    padding: 1rem 1.5rem;
    background: rgba(241, 245, 249, 0.5);
    /* ShipmentPage hover bg */
    border-top: 1px solid #e2e8f0;
    /* ShipmentPage border */
    display: flex;
    justify-content: flex-end;
}

.assignment-actions {
    display: flex;
    gap: 1rem;
}

.adjust-btn {
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    /* ShipmentPage button bg */
    border: none;
    border-radius: 20px;
    padding: 0.5rem 1rem;
    color: #ffffff;
    /* ShipmentPage button text */
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.adjust-btn:hover {
    transform: scale(1.05);
}

.crucible-btn {
    background: #dc3545;
    /* ShipmentPage close button */
    border: none;
    border-radius: 20px;
    padding: 0.5rem 1rem;
    color: #ffffff;
    /* ShipmentPage button text */
    font-weight: 600;
    transition: transform 0.2s ease;
}

.crucible-btn:hover {
    transform: scale(1.05);
}

/* react_frontend/src/pages/InventoryPage.css */

/* Add these styles after .section-controls or at the end */

.filter-controls {
    background: rgba(255, 255, 255, 0.9);
    /* ShipmentPage card bg */
    border-radius: 12px;
    padding: 1.5rem;
    margin: 1rem 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
}

.filter-controls h4 {
    color: #1a202c;
    /* ShipmentPage header text */
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.filter-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.filter-group label {
    color: #1a202c;
    /* ShipmentPage header text */
    font-weight: 600;
}

.filter-group input,
.filter-group select {
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    /* ShipmentPage border */
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8);
    /* ShipmentPage bg */
    transition: border-color 0.2s ease;
    color: #1a202c;
    /* ShipmentPage text */
    font-size: 1rem;
}

.filter-group input:focus,
.filter-group select:focus {
    border-color: #4c51bf;
    /* ShipmentPage accent */
    outline: none;
    box-shadow: 0 0 0 4px rgba(76, 81, 191, 0.2);
}

/* Adjust for date range inputs to display side-by-side on larger screens */
@media (min-width: 768px) {
    .filter-group:first-child {
        flex-direction: row;
        gap: 1rem;
        align-items: center;
    }

    .filter-group:first-child input {
        flex: 1;
    }
}

/* react_frontend/src/pages/InventoryPage.css */

/* Add these styles after .section-controls or at the end */

.filter-bar {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 1rem 10px;
    background: rgba(255, 255, 255, 0.9);
    /* ShipmentPage card bg */
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    /* Subtle shadow */
    margin: 1rem 10px;
    align-items: center;
}

.filter-item {
    display: flex;
    flex-direction: column;
    min-width: 150px;
}

.filter-item input,
.filter-item select {
    padding: 0.5rem 0.75rem;
    border: 1px solid #e2e8f0;
    /* ShipmentPage border */
    border-radius: 8px;
    background: #ffffff;
    /* ShipmentPage card bg */
    color: #1a202c;
    /* ShipmentPage text */
    font-size: 0.9rem;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.filter-item input:focus,
.filter-item select:focus {
    border-color: #4c51bf;
    /* ShipmentPage accent */
    outline: none;
    box-shadow: 0 0 0 3px rgba(76, 81, 191, 0.2);
}

.filter-item input::placeholder {
    color: #a0aec0;
    /* Lighter subtext */
    font-style: italic;
}

/* Date range styling */
.filter-item.date-range {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.filter-item.date-range input {
    width: 120px;
}

.filter-item.date-range span {
    color: #4c51bf;
    /* ShipmentPage accent */
    font-weight: 600;
}

/* Clear filter button */
.clear-filter-btn {
    background: #dc3545;
    /* ShipmentPage close button */
    border: none;
    border-radius: 20px;
    padding: 0.5rem 1rem;
    color: #ffffff;
    /* ShipmentPage button text */
    font-weight: 600;
    font-size: 0.9rem;
    cursor: pointer;
    transition: transform 0.2s ease, background 0.2s ease;
}

.clear-filter-btn:hover {
    transform: scale(1.05);
    background: #c82333;
    /* Darker red on hover */
}

/* Rest of the existing CSS remains unchanged */