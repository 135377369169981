/* react_frontend/src/pages/Store/OrdersPage.css */
.cosmic-voyage {
    position: relative;
    background: linear-gradient(135deg, #f7fafc 0%, #e2e8f0 100%);
    /* ShipmentPage main bg */
    min-height: 100vh;
    padding: 0;
    overflow-x: hidden;
    color: #1a202c;
    /* ShipmentPage header text */
    font-family: 'Orbitron', sans-serif;
}

.stellar-header {
    background: linear-gradient(90deg, #d1d5db, #e5e7eb);
    /* ShipmentPage header bg */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    /* ShipmentPage shadow */
    padding: 2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-bottom: 2px solid #e2e8f0;
    /* ShipmentPage border */
    position: sticky;
    top: 0;
    z-index: 1;
    text-align: center;
    border-radius: 40px;
    flex-wrap: wrap;
}

.nebula-title {
    color: #1a202c;
    /* ShipmentPage header text */
    font-size: 2.5rem;
    font-weight: 900;
    text-transform: uppercase;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    /* ShipmentPage shadow */
    animation: nebulaPulse 3s infinite alternate;
}

@keyframes nebulaPulse {
    0% {
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    }

    100% {
        text-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }
}

.hyperdrive-button {
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    /* ShipmentPage button bg */
    border: none;
    border-radius: 25px;
    padding: 0.8rem 2rem;
    color: #ffffff;
    /* ShipmentPage button text */
    font-weight: 700;
    text-transform: uppercase;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
}

.hyperdrive-button:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(76, 81, 191, 0.3);
    /* ShipmentPage button shadow */
}

.hyperdrive-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.warp-sequence::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid #ffffff;
    /* ShipmentPage white */
    border-radius: 50%;
    animation: warpSpin 1s infinite linear;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

@keyframes warpSpin {
    0% {
        transform: translateY(-50%) rotate(0deg);
    }

    100% {
        transform: translateY(-50%) rotate(360deg);
    }
}

.starship-console {
    margin: 2rem auto;
    max-width: 400px;
    position: relative;
}

.console-label {
    color: #4c51bf;
    /* ShipmentPage accent */
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.quantum-dropdown {
    width: 100%;
    padding: 0.9rem;
    border: 2px solid #e2e8f0;
    /* ShipmentPage border */
    border-radius: 15px;
    background: #ffffff;
    /* ShipmentPage card bg */
    color: #1a202c;
    /* ShipmentPage text */
    font-size: 1.1rem;
    appearance: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.quantum-dropdown:focus {
    border-color: #4c51bf;
    /* ShipmentPage accent */
    box-shadow: 0 0 10px rgba(76, 81, 191, 0.2);
    /* ShipmentPage glow */
    outline: none;
}

.cosmic-radar {
    margin: 2rem auto;
    max-width: 600px;
    position: relative;
}

.radar-beam {
    width: 100%;
    padding: 1rem 1.5rem;
    border: 2px solid #e2e8f0;
    border-radius: 30px;
    background: rgba(255, 255, 255, 0.9);
    color: #1a202c;
    font-size: 1.2rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.radar-beam:focus {
    border-color: #4c51bf;
    box-shadow: 0 0 15px rgba(76, 81, 191, 0.2);
    outline: none;
}

.radar-beam::placeholder {
    color: #718096;
}

.filter-controls {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    flex-wrap: wrap;
}

.filter-dropdown {
    padding: 0.8rem;
    border: 2px solid #e2e8f0;
    border-radius: 15px;
    background: #ffffff;
    color: #1a202c;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    min-width: 150px;
}

.filter-dropdown:focus {
    border-color: #4c51bf;
    box-shadow: 0 0 10px rgba(76, 81, 191, 0.2);
    outline: none;
}

.cosmic-storm {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    /* ShipmentPage loading bg */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    color: #4c51bf;
    /* ShipmentPage accent */
    font-size: 1.8rem;
}

.vortex-spinner {
    width: 60px;
    height: 60px;
    border: 4px solid #e2e8f0;
    /* ShipmentPage border */
    border-top: 4px solid #4c51bf;
    /* ShipmentPage accent */
    border-radius: 50%;
    animation: vortexSwirl 1.2s linear infinite;
}

@keyframes vortexSwirl {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.distress-signal,
.void-message {
    text-align: center;
    color: #e53e3e;
    /* ShipmentPage error (close to #dc3545) */
    font-size: 1.5rem;
    margin: 2rem;
}

.orbital-cluster {
    margin: 0 2rem;
}

.asteroid-belt {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    gap: 0.8rem;
    color: #4c51bf;
    /* ShipmentPage accent */
    font-weight: 600;
}

.asteroid-belt input {
    width: 22px;
    height: 22px;
    cursor: pointer;
    accent-color: #4c51bf;
    /* ShipmentPage accent */
}

.meteor-pod {
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.9);
    /* ShipmentPage card bg */
    border-radius: 15px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
    /* ShipmentPage shadow */
    padding: 1.2rem 1.8rem;
    margin-bottom: 1.2rem;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease, border 0.3s ease;
    border: 2px solid #e2e8f0;
    /* ShipmentPage border */
}

.meteor-pod:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(76, 81, 191, 0.2);
    /* ShipmentPage hover shadow */
    border: 2px solid #4c51bf;
    /* ShipmentPage accent */
}

.meteor-pod input {
    margin-right: 1.5rem;
    cursor: pointer;
    accent-color: #4c51bf;
    /* ShipmentPage accent */
}

.meteor-trail {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    width: 100%;
    position: relative;
    /* Added for positioning adjust-details */
    align-items: center;
    /* Align items vertically */
}

.adjust-toggle {
    background: transparent;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    transition: transform 0.2s ease;
    margin-left: auto;
    /* Push to the right */
}

.adjust-toggle:hover {
    transform: scale(1.1);
}

.adjust-toggle svg {
    fill: #4c51bf;
    /* ShipmentPage accent */
}

.adjust-details {
    position: absolute;
    top: 100%;
    right: 0;
    /* Align to the right side of meteor-trail */
    background: #ffffff;
    /* ShipmentPage card bg */
    border: 1px solid #e2e8f0;
    /* ShipmentPage border */
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    /* ShipmentPage shadow */
    z-index: 10;
    max-width: 300px;
    /* Limit width */
    word-wrap: break-word;
    /* Ensure long text wraps */
}

.details-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.details-content p {
    margin: 0;
    font-size: 1rem;
    color: #1a202c;
    /* ShipmentPage text */
}

.details-content strong {
    color: #4c51bf;
    /* ShipmentPage accent */
}

.okay-btn {
    background: #4c51bf;
    /* ShipmentPage accent */
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    color: #ffffff;
    /* ShipmentPage button text */
    font-weight: 600;
    cursor: pointer;
    transition: transform 0.2s ease;
    align-self: center;
    margin-top: 0.5rem;
}

.okay-btn:hover {
    transform: scale(1.05);
}

.comet-id {
    font-weight: 800;
    color: #1a202c;
    /* ShipmentPage header text */
}

.stardust-date,
.luminosity {
    color: #718096;
    /* ShipmentPage subtext */
    font-weight: 500;
}

.gravity-well {
    border-radius: 25px;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;
    color: #070707;
    /* ShipmentPage button text */
}

.gravity-well.fulfilled {
    background: #e6fffa;
    /* ShipmentPage fulfilled bg */
    color: #2c7a7b;
    /* ShipmentPage fulfilled text */
}

.gravity-well.cancelled {
    background: #fefcbf;
    /* ShipmentPage pending bg */
    color: #975a16;
    /* ShipmentPage pending text */
}

.orbital-history {
    color: #4c51bf;
    /* ShipmentPage accent */
    font-weight: 600;
}

.wormhole-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    margin-top: 2.5rem;
}

.jump-gate {
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    /* ShipmentPage button bg */
    border: none;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    color: #ffffff;
    /* ShipmentPage button text */
    font-weight: 700;
    text-transform: uppercase;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
}

.jump-gate:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.jump-gate:not(:disabled):hover {
    transform: scale(1.15);
    box-shadow: 0 6px 12px rgba(76, 81, 191, 0.3);
    /* ShipmentPage button shadow */
}

.sector-coordinate {
    color: #1a202c;
    /* ShipmentPage header text */
    font-size: 1.5rem;
    font-weight: 700;
}

.stellar-hologram {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease;
}

.stellar-hologram.active {
    opacity: 1;
    visibility: visible;
}

.holo-shield {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    /* ShipmentPage overlay (adjusted) */
}

.holo-core {
    background: rgba(255, 255, 255, 0.9);
    /* ShipmentPage modal bg */
    border-radius: 25px;
    padding: 2.5rem;
    width: 90%;
    max-width: 85vw;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    /* ShipmentPage shadow */
    transform: scale(0.9);
    animation: holoMaterialize 0.6s ease forwards;
    max-height: 90vh;
    overflow-y: auto;
    border: 2px solid #e2e8f0;
    /* ShipmentPage border */
    position: relative;
}

@keyframes holoMaterialize {
    to {
        transform: scale(1);
    }
}

.eject-core {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    background: #dc3545;
    /* ShipmentPage close button */
    border: none;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    color: #ffffff;
    /* ShipmentPage button text */
    font-size: 1.5rem;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.eject-core:hover {
    transform: rotate(90deg);
}

.holo-signature {
    color: #4c51bf;
    /* ShipmentPage accent */
    font-size: 2rem;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 2rem;
}

.holo-matrix {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    max-height: 100vh;
    max-width: 100vw;
}

.telemetry-feed,
.cargo-bay,
.chrono-stream {
    background: rgba(241, 245, 249, 0.5);
    /* ShipmentPage hover bg */
    border-radius: 15px;
    padding: 1.8rem;
    border: 1px solid #e2e8f0;
    /* ShipmentPage border */
    transition: box-shadow 0.3s ease;
}

.telemetry-feed:hover,
.cargo-bay:hover,
.chrono-stream:hover {
    box-shadow: 0 0 20px rgba(76, 81, 191, 0.2);
    /* ShipmentPage hover shadow */
}

.telemetry-feed p {
    margin: 0.8rem 0;
    color: #1a202c;
    /* ShipmentPage text */
    font-size: 1.1rem;
}

.cargo-bay h4,
.chrono-stream h4,
.telemetry-label {
    color: #4c51bf;
    /* ShipmentPage accent */
    font-size: 1.4rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.cargo-unit {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0.5rem;
    background: #ffffff;
    /* ShipmentPage card bg */
    border-radius: 10px;
}

.cargo-beacon {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 8px;
    margin-right: 1rem;
    border: 2px solid #e2e8f0;
    /* ShipmentPage border */
}

.cargo-unit p {
    color: #1a202c;
    /* ShipmentPage text */
    font-size: 1.1rem;
}

.discount-flux {
    color: #975a16;
    /* ShipmentPage pending text (for emphasis) */
    font-weight: 600;
}

.no-flux,
.no-beacon,
.no-transmission,
.chrono-void {
    color: #718096;
    /* ShipmentPage subtext */
    font-style: italic;
}

.event-sequence,
.transmission-log {
    list-style: none;
    padding: 0;
}

.chrono-pulse,
.log-entry {
    margin-bottom: 1rem;
    padding: 0.8rem;
    background: #ffffff;
    /* ShipmentPage card bg */
    border-radius: 8px;
    color: #1a202c;
    /* ShipmentPage text */
    font-size: 1.1rem;
    word-wrap: break-word;
}

.time-warp {
    color: #e53e3e;
    /* ShipmentPage error (close to #dc3545) */
    font-weight: 600;
    margin-right: 0.5rem;
}

.chrono-entry {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.signal-input,
.time-input {
    padding: 0.8rem;
    border: 1px solid #e2e8f0;
    /* ShipmentPage border */
    border-radius: 10px;
    background: #ffffff;
    /* ShipmentPage card bg */
    color: #1a202c;
    /* ShipmentPage text */
    font-size: 1.1rem;
    transition: border-color 0.3s ease;
}

.signal-input:focus,
.time-input:focus {
    border-color: #4c51bf;
    /* ShipmentPage accent */
    outline: none;
    box-shadow: 0 0 10px rgba(76, 81, 191, 0.2);
    /* ShipmentPage glow */
}

.chrono-transmitter {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
    flex-wrap: wrap;
}

.transmit-pulse {
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    /* ShipmentPage button bg */
    border: none;
    border-radius: 15px;
    padding: 0.8rem 1.5rem;
    color: #ffffff;
    /* ShipmentPage button text */
    font-weight: 700;
    text-transform: uppercase;
    transition: transform 0.3s ease;
}

.transmit-pulse:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(76, 81, 191, 0.3);
    /* ShipmentPage button shadow */
}

.beacon-signal a {
    color: #4c51bf;
    /* ShipmentPage accent */
    text-decoration: none;
    font-weight: 600;
}

.beacon-signal a:hover {
    text-decoration: underline;
}

.frequency-input,
.log-input {
    width: 100%;
    padding: 0.9rem;
    border: 1px solid #e2e8f0;
    /* ShipmentPage border */
    border-radius: 15px;
    background: #ffffff;
    /* ShipmentPage card bg */
    color: #1a202c;
    /* ShipmentPage text */
    font-size: 1.1rem;
    transition: border-color 0.3s ease;
}

.frequency-input:focus,
.log-input:focus {
    border-color: #4c51bf;
    /* ShipmentPage accent */
    outline: none;
    box-shadow: 0 0 10px rgba(76, 81, 191, 0.2);
    /* ShipmentPage glow */
}

.distress-call {
    color: #e53e3e;
    /* ShipmentPage error (close to #dc3545) */
    text-align: center;
    margin: 1.5rem 0;
    font-size: 1.2rem;
}

.engage-thrusters {
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    /* ShipmentPage button bg */
    border: none;
    border-radius: 25px;
    padding: 1rem 2rem;
    color: #ffffff;
    /* ShipmentPage button text */
    font-weight: 700;
    text-transform: uppercase;
    align-self: center;
    margin-top: 2rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.engage-thrusters:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(76, 81, 191, 0.3);
    /* ShipmentPage button shadow */
}

.holo-static {
    color: #718096;
    /* ShipmentPage subtext */
    text-align: center;
    font-size: 1.5rem;
}

.transmission-log .log-entry {
    cursor: pointer;
    padding: 0.8rem;
    transition: background 0.3s ease, transform 0.3s ease;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.transmission-log .log-entry:hover {
    background: rgba(241, 245, 249, 0.5);
    /* ShipmentPage hover bg */
    transform: translateX(5px);
}

.status-cancelled {
    color: #e53e3e;
    /* ShipmentPage error (close to #dc3545) */
    font-weight: 700;
    margin-left: 0.5rem;
}

.status-undelivered {
    color: #975a16;
    /* ShipmentPage pending text */
    font-weight: 700;
    margin-left: 0.5rem;
}

.status-fulfilled {
    color: #2c7a7b;
    /* ShipmentPage fulfilled text */
    font-weight: 700;
    text-shadow: none;
    /* Remove glow for simplicity */
}

.status-unfulfilled {
    color: #975a16;
    /* ShipmentPage pending text (no direct unfulfilled match) */
    font-weight: 700;
    text-shadow: none;
}

.fulfillment-echo {
    color: #4c51bf;
    /* ShipmentPage accent */
    font-weight: 600;
}

.tag-cluster {
    color: #718096;
    /* ShipmentPage subtext */
    font-weight: 500;
    font-size: 0.9rem;
}

.load-more-thrusters {
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    /* ShipmentPage button bg */
    border: none;
    border-radius: 25px;
    padding: 0.8rem 1.5rem;
    color: #ffffff;
    /* ShipmentPage button text */
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 1rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.load-more-thrusters:hover {
    transform: scale(1.1);
    box-shadow: 0 6px 12px rgba(76, 81, 191, 0.3);
    /* ShipmentPage button shadow */
}

.load-more-thrusters:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.total-spent {
    color: #1a202c;
    /* ShipmentPage header text (for emphasis) */
    font-weight: 600;
    margin-left: 0.5rem;
}

.rto-chance {
    color: #e53e3e;
    /* ShipmentPage error (close to #dc3545) */
    font-weight: 600;
    margin-left: 0.5rem;
}

.old-customer {
    color: #2c7a7b;
    /* ShipmentPage fulfilled text (for positive connotation) */
    font-weight: 600;
    margin-left: 0.5rem;
}

.belt-control {
    display: flex;
    align-items: center;
}

/* Keep existing styles, only add or modify what's needed for the new design */

.cosmic-voyage {
    scroll-behavior: smooth;
    overflow-y: auto;
}

.orbital-cluster {
    margin: 0 1rem;
    max-height: 70vh;
    overflow-y: auto;
    padding: 1rem;
}

.shipment-card {
    background: rgba(255, 255, 255, 0.95);
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    padding: 1rem;
    transition: all 0.3s ease;
    border: 1px solid #e2e8f0;
    cursor: pointer;
}

.shipment-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 18px rgba(76, 81, 191, 0.2);
    border-color: #4c51bf;
}

.shipment-card.expanded {
    padding-bottom: 1.5rem;
}

.card-main {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.card-main input {
    margin: 0;
    width: 18px;
    height: 18px;
    accent-color: #4c51bf;
}

.order-info,
.customer-info,
.status-info,
.total-info {
    display: flex;
    flex-direction: column;
    min-width: 120px;
    gap: 0.3rem;
}

.order-info .comet-id {
    font-size: 1.2rem;
    font-weight: 700;
}

.order-info .stardust-date {
    font-size: 0.9rem;
}

.customer-info .customer-name {
    font-weight: 600;
    color: #1a202c;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.customer-info .customer-email {
    font-size: 0.85rem;
    color: #718096;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.status-info {
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
}

.status-info .gravity-well {
    padding: 0.3rem 0.8rem;
    font-size: 0.9rem;
}

.status-info .payment-status {
    padding: 0.3rem 0.8rem;
    font-size: 0.9rem;
    border-radius: 12px;
}

.payment-status.paid {
    background: #e6fffa;
    color: #2c7a7b;
}

.payment-status.pending,
.payment-status.partially_paid {
    background: #fefcbf;
    color: #975a16;
}

.total-info {
    margin-left: auto;
    align-items: flex-end;
}

.total-info .luminosity {
    font-size: 1.1rem;
    font-weight: 600;
}

.total-info .customer-status {
    font-size: 0.85rem;
    padding: 0.2rem 0.5rem;
    border-radius: 8px;
}

.customer-status.rto-chance {
    background: #e53e3e;
    color: #fff;
}

.customer-status.old-customer {
    background: #2c7a7b;
    color: #fff;
}

.card-details {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.shipment-card.expanded .card-details {
    max-height: 200px;
    /* Adjust as needed */
    margin-top: 1rem;
}

.detail-row {
    display: flex;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.detail-label {
    font-weight: 600;
    color: #4c51bf;
    flex-shrink: 0;
}

.detail-value {
    color: #1a202c;
    font-size: 0.9rem;
    word-wrap: break-word;
    max-width: 80%;
}

/* Hide old .meteor-pod and .adjust-details */
.meteor-pod,
.adjust-details {
    display: none;
}

/* Keep most existing styles, only add or modify what's needed for the new design */

.cosmic-voyage {
    scroll-behavior: smooth;
    overflow-y: auto;
    padding-bottom: 2rem;
}

/* Header */
.stellar-header {
    justify-content: space-between;
    padding: 1.5rem 2rem;
    border-radius: 0 0 20px 20px;
}

.header-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.order-count {
    font-size: 1.2rem;
    color: #4c51bf;
    font-weight: 600;
}

.hyperdrive-button {
    padding: 0.6rem 1.5rem;
    font-size: 1rem;
}

/* Store Selection */
.starship-console {
    margin: 1rem auto;
    max-width: 100%;
    padding: 0 1rem;
}

.console-row {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: rgba(255, 255, 255, 0.9);
    padding: 0.5rem 1rem;
    border-radius: 12px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.console-label {
    font-size: 1.2rem;
    margin: 0;
}

.quantum-dropdown {
    padding: 0.6rem;
    font-size: 1rem;
    flex-grow: 1;
    max-width: 300px;
}

/* Search and Filters */
.cosmic-radar {
    margin: 1rem auto;
    max-width: 100%;
    padding: 0 1rem;
}

.radar-row {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    background: rgba(255, 255, 255, 0.9);
    padding: 1rem;
    border-radius: 12px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.radar-beam {
    flex-grow: 1;
    padding: 0.8rem 1rem;
    font-size: 1rem;
    min-width: 200px;
}

.filter-controls {
    display: flex;
    gap: 0.5rem;
}

.filter-dropdown {
    padding: 0.6rem;
    font-size: 0.9rem;
    min-width: 120px;
}

/* Loading and Error States */
.cosmic-storm {
    background: rgba(255, 255, 255, 0.95);
    font-size: 1.5rem;
    gap: 1rem;
}

.distress-signal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 2rem auto;
    max-width: 400px;
    background: rgba(255, 255, 255, 0.9);
    padding: 1rem;
    border-radius: 12px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.retry-button {
    background: #4c51bf;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    font-weight: 600;
    transition: transform 0.2s ease;
}

.retry-button:hover {
    transform: scale(1.05);
}

.void-message {
    margin: 2rem auto;
    text-align: center;
    font-size: 1.2rem;
    color: #718096;
}

/* Order List */
.orbital-cluster {
    margin: 0 1rem;
    max-height: 70vh;
    overflow-y: auto;
    padding: 0;
}

.asteroid-belt {
    padding: 0.5rem 1rem;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    margin-bottom: 1rem;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.shipment-card {
    margin: 0 0 1rem 0;
    padding: 1rem;
}

.card-details .detail-row {
    align-items: baseline;
}

.wormhole-nav {
    margin: 1rem 0;
    gap: 1rem;
}

.jump-gate {
    width: 40px;
    height: 40px;
    font-size: 1rem;
}

.sector-coordinate {
    font-size: 1.2rem;
}

/* Modal */
.holo-core {
    padding: 2rem;
    max-width: 85vw;
}

.holo-signature {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
}

.holo-matrix {
    gap: 1.5rem;
    max-height: 100vh;
    max-width: 100vw;
}

.shipment-summary {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 1rem;
    padding: 1.5rem;
    background: rgba(241, 245, 249, 0.5);
    border-radius: 12px;
}

.summary-item {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
}

.summary-label {
    font-weight: 600;
    color: #4c51bf;
}

.customer-details,
.cargo-bay,
.tracking-info,
.chrono-stream,
.tags-editor,
.past-orders {
    padding: 1.5rem;
    background: rgba(241, 245, 249, 0.5);
    border-radius: 12px;
}

.customer-details h4,
.cargo-bay h4,
.tracking-info h4,
.chrono-stream h4,
.tags-editor h4,
.past-orders h4 {
    margin-bottom: 1rem;
}

.cargo-unit {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;
}

.item-details {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.chrono-entry {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
}

.signal-input {
    width: 100%;
}

.chrono-transmitter {
    flex-direction: column;
    gap: 0.5rem;
}

.transmit-pulse {
    padding: 0.6rem 1rem;
    align-self: flex-start;
}

.frequency-input {
    margin-top: 0.5rem;
}

.transmission-log .log-entry {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    font-size: 0.9rem;
}

.load-more-thrusters {
    padding: 0.6rem 1rem;
}

.engage-thrusters {
    padding: 0.8rem 1.5rem;
    margin-top: 1rem;
}

/* Hide unused styles */
.meteor-pod,
.adjust-details {
    display: none;
}

/* ... (Keep all previous styles unchanged until the modal section) */

/* Past Shipments */
.past-orders {
    padding: 1.5rem;
    background: rgba(241, 245, 249, 0.5);
    border-radius: 12px;
}

.past-orders h4 {
    margin-bottom: 1rem;
    color: #4c51bf;
    font-size: 1.4rem;
    font-weight: 700;
}

.past-orders-table {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1.5fr;
    /* Adjusted for status column */
    gap: 0.5rem;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.past-orders-header {
    display: contents;
}

.past-orders-header span {
    padding: 0.5rem;
    background: #e2e8f0;
    font-weight: 600;
    color: #4c51bf;
    text-transform: uppercase;
    font-size: 0.9rem;
}

.past-order-row {
    display: contents;
    cursor: pointer;
}

.past-order-row:hover>* {
    background: rgba(241, 245, 249, 0.7);
}

.past-order-row>span {
    padding: 0.75rem 0.5rem;
    border-bottom: 1px solid #e2e8f0;
    font-size: 0.9rem;
    color: #1a202c;
}

.past-order-id {
    font-weight: 600;
}

.past-order-date,
.past-order-total {
    color: #718096;
}

.past-order-status {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.status-badge {
    padding: 0.2rem 0.5rem;
    border-radius: 12px;
    font-size: 0.85rem;
    font-weight: 600;
    text-transform: uppercase;
}

.status-badge.delivered {
    background: #e6fffa;
    color: #2c7a7b;
}

.status-badge.cancelled {
    background: #fefcbf;
    color: #975a16;
}

.status-badge.undelivered {
    background: #fed7d7;
    color: #e53e3e;
}

.fulfillment-note {
    font-size: 0.8rem;
    color: #4c51bf;
    font-style: italic;
}

.load-more-btn {
    display: block;
    margin: 1rem auto 0;
    padding: 0.6rem 1.5rem;
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    color: #fff;
    border: none;
    border-radius: 20px;
    font-weight: 600;
    transition: transform 0.2s ease, opacity 0.2s ease;
}

.load-more-btn:hover:not(:disabled) {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(76, 81, 191, 0.3);
}

.load-more-btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.loading-dots::after {
    content: '...';
    animation: dots 1s infinite steps(3);
}

@keyframes dots {
    0% {
        content: '.';
    }

    33% {
        content: '..';
    }

    66% {
        content: '...';
    }
}

.no-past-orders {
    text-align: center;
    color: #718096;
    font-size: 1rem;
    font-style: italic;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
}

/* Order Notes */
.order-notes {
    padding: 1.5rem;
    background: rgba(241, 245, 249, 0.5);
    border-radius: 12px;
}

.order-notes h4 {
    margin-bottom: 1rem;
    color: #4c51bf;
    font-size: 1.4rem;
    font-weight: 700;
}

.note-entry {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    margin-bottom: 1rem;
    padding: 0.8rem;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.note-label {
    font-weight: 600;
    color: #975a16;
    /* Distinct color for emphasis */
}

.note-text {
    color: #1a202c;
    font-size: 1rem;
    word-wrap: break-word;
}

.no-notes {
    text-align: center;
    color: #718096;
    font-size: 1rem;
    font-style: italic;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
}

/* Compact Order Details */
.order-customer-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Two equal columns */
    gap: 1rem;
    padding: 1rem;
    background: rgba(241, 245, 249, 0.5);
    border-radius: 12px;
}

.order-details-column {
    display: flex;
    flex-direction: column;
}

.order-details-column h4,
.customer-details-column h4,
.items-column h4,
.notes-column h4,
.tags-column h4,
.tracking-column h4 {
    margin-bottom: 0.5rem;
    color: #4c51bf;
    font-size: 1.2rem;
    font-weight: 700;
}

.compact-summary {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.3rem 1rem;
    font-size: 0.9rem;
}

.compact-summary .summary-label {
    font-weight: 600;
    color: #4c51bf;
    margin-right: 0.5rem;
}

.compact-summary span:not(.summary-label) {
    color: #1a202c;
}

.customer-details-column {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.customer-details-column p {
    margin: 0;
    font-size: 0.9rem;
    color: #1a202c;
}

.customer-details-column strong {
    color: #4c51bf;
}

/* Items to Ship + Order Notes */
.items-notes-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding: 1rem;
    background: rgba(241, 245, 249, 0.5);
    border-radius: 12px;
}

.items-column {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.items-column .cargo-unit {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.3rem;
}

.items-column .item-details {
    display: flex;
    flex-direction: column;
    gap: 0.1rem;
    font-size: 0.9rem;
}

.notes-column {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.note-entry {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    background: #ffffff;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.note-label {
    font-weight: 600;
    color: #975a16;
    font-size: 0.9rem;
}

.note-text {
    color: #1a202c;
    font-size: 0.9rem;
    word-wrap: break-word;
}

.no-notes {
    text-align: center;
    color: #718096;
    font-size: 0.9rem;
    font-style: italic;
    padding: 0.5rem;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 6px;
}

/* Compact Tags + Tracking */
.tags-tracking-card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding: 1rem;
    background: rgba(241, 245, 249, 0.5);
    border-radius: 12px;
}

.tags-column {
    display: flex;
    flex-direction: column;
}

.compact-frequency-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 10px;
    background: #ffffff;
    color: #1a202c;
    font-size: 0.9rem;
    transition: border-color 0.3s ease;
}

.compact-frequency-input:focus {
    border-color: #4c51bf;
    outline: none;
    box-shadow: 0 0 10px rgba(76, 81, 191, 0.2);
}

.tracking-column {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.tracking-column .beacon-signal {
    margin: 0;
    font-size: 0.9rem;
}

.tracking-column .no-beacon {
    margin: 0;
    font-size: 0.9rem;
    color: #718096;
    font-style: italic;
}

/* Past Shipments (unchanged but moved up) */
.past-orders {
    padding: 1rem;
    background: rgba(241, 245, 249, 0.5);
    border-radius: 12px;
}

.past-orders h4 {
    margin-bottom: 0.5rem;
}

/* Remove old standalone styles */
.shipment-summary,
.customer-details,
.cargo-bay,
.order-notes,
.tags-editor,
.tracking-info {
    display: none;
}

/* Adjust existing styles for compactness */
.holo-matrix {
    gap: 1rem;
    /* Reduced from 1.5rem */
}

.chrono-stream {
    padding: 1rem;
}

.chrono-entry {
    gap: 0.3rem;
    margin-bottom: 0.5rem;
}

.chrono-transmitter {
    gap: 0.5rem;
}

/* Modal Base Styles (Unchanged for Desktop/Tablet) */
.stellar-hologram {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s ease;
}

.stellar-hologram.active {
    opacity: 1;
    visibility: visible;
}

.holo-shield {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
}

.holo-core {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 25px;
    padding: 2rem;
    width: 90%;
    max-width: 85vw;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    transform: scale(0.9);
    animation: holoMaterialize 0.6s ease forwards;
    max-height: 90vh;
    overflow-y: auto;
    border: 2px solid #e2e8f0;
    position: relative;
}

.holo-signature {
    color: #4c51bf;
    font-size: 1.8rem;
    font-weight: 900;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
}

.holo-matrix {
    gap: 1rem;
}

/* Mobile Optimizations (Below 600px) */
@media (max-width: 600px) {
    .holo-core {
        padding: 1rem;
        width: 95%;
        max-width: 100vw;
        max-height: 95vh;
        border-radius: 15px;
    }

    .holo-signature {
        font-size: 1.4rem;
        margin-bottom: 1rem;
    }

    .holo-matrix {
        gap: 0.75rem;
    }

    /* Past Shipments */
    .past-orders {
        padding: 0.75rem;
    }

    .past-orders h4 {
        font-size: 1.1rem;
    }

    .past-orders-table {
        grid-template-columns: 1fr 1fr;
        /* Reduce to two columns on mobile */
        font-size: 0.8rem;
    }

    .past-orders-header span:nth-child(3),
    .past-order-row span:nth-child(3) {
        display: none;
        /* Hide Total column on mobile */
    }

    .past-order-row>span {
        padding: 0.5rem 0.3rem;
    }

    .load-more-btn {
        padding: 0.4rem 1rem;
        font-size: 0.9rem;
    }

    /* Tags (standalone) */
    .tags-editor {
        padding: 0.75rem;
    }

    .tags-editor h4 {
        font-size: 1.1rem;
        margin-bottom: 0.3rem;
    }

    .frequency-input {
        padding: 0.4rem;
        font-size: 0.85rem;
    }

    /* Items to Ship + Order Notes */
    .items-notes-card {
        grid-template-columns: 1fr;
        /* Stack columns */
        gap: 0.75rem;
        padding: 0.75rem;
    }

    .items-column h4,
    .notes-column h4 {
        font-size: 1.1rem;
    }

    .cargo-unit {
        gap: 0.5rem;
    }

    .cargo-beacon {
        width: 40px;
        height: 40px;
    }

    .item-details {
        font-size: 0.85rem;
    }

    .note-entry {
        padding: 0.4rem;
        margin-bottom: 0.3rem;
    }

    .note-label,
    .note-text,
    .no-notes {
        font-size: 0.85rem;
    }

    /* Order Details + Customer Details */
    .order-customer-card {
        grid-template-columns: 1fr;
        /* Stack columns */
        gap: 0.75rem;
        padding: 0.75rem;
    }

    .order-details-column h4,
    .customer-details-column h4 {
        font-size: 1.1rem;
    }

    .compact-summary {
        font-size: 0.85rem;
        gap: 0.2rem 0.75rem;
    }

    .customer-details-column p {
        font-size: 0.85rem;
    }

    /* Tags + Tracking */
    .tags-tracking-card {
        grid-template-columns: 1fr;
        /* Stack columns */
        gap: 0.75rem;
        padding: 0.75rem;
    }

    .tags-column h4,
    .tracking-column h4 {
        font-size: 1.1rem;
    }

    .compact-frequency-input {
        padding: 0.4rem;
        font-size: 0.85rem;
    }

    .tracking-column .beacon-signal,
    .tracking-column .no-beacon {
        font-size: 0.85rem;
    }

    /* Shipping Notes */
    .chrono-stream {
        padding: 0.75rem;
    }

    .chrono-stream h4 {
        font-size: 1.1rem;
    }

    .chrono-entry {
        gap: 0.2rem;
    }

    .time-warp {
        font-size: 0.85rem;
    }

    .signal-input {
        padding: 0.4rem;
        font-size: 0.85rem;
    }

    .chrono-transmitter {
        gap: 0.3rem;
    }

    .transmit-pulse {
        padding: 0.4rem 0.8rem;
        font-size: 0.9rem;
    }

    /* Update Button */
    .engage-thrusters {
        padding: 0.6rem 1rem;
        font-size: 0.9rem;
        margin-top: 0.75rem;
    }

    .distress-call {
        font-size: 1rem;
        margin: 0.75rem 0;
    }
}

/* Tablet Adjustments (600px - 900px, optional refinement) */
@media (min-width: 601px) and (max-width: 900px) {
    .holo-core {
        padding: 1.5rem;
        max-width: 90vw;
    }

    .past-orders-table {
        grid-template-columns: 1fr 1fr 1fr 1.5fr;
        /* Keep full layout */
    }
}