/* react_frontend/src/pages/LabourEfficiencyPage.css */

.iron-star-guild {
    background: linear-gradient(135deg, #f7fafc 0%, #e2e8f0 100%);
    /* ShipmentPage main bg */
    min-height: 100vh;
    padding: 0;
    overflow-x: hidden;
}

.forge-star {
    background: linear-gradient(90deg, #d1d5db, #e5e7eb);
    /* ShipmentPage header bg */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    /* ShipmentPage shadow */
    padding: 2rem;
    text-align: center;
}

.forge-star h3 {
    color: #1a202c;
    /* ShipmentPage header text */
    font-size: 2.5rem;
    font-weight: 700;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    /* Adjusted for ShipmentPage */
}

.guild-loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    /* ShipmentPage loading bg */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    color: #4c51bf;
    /* ShipmentPage accent */
    font-size: 1.5rem;
}

.gear-spinner {
    width: 50px;
    height: 50px;
    background: url('leather.png'), radial-gradient(circle, #4c51bf 20%, transparent 70%);
    /* ShipmentPage accent */
    border-radius: 50%;
    animation: gearTurn 2s linear infinite;
}

@keyframes gearTurn {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.guild-error {
    text-align: center;
    color: #dc3545;
    /* ShipmentPage close button (error) */
    font-size: 1.25rem;
    margin: 2rem;
}

.sort-controls {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 2rem;
}

.sort-controls button {
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    /* ShipmentPage button bg */
    border: none;
    border-radius: 20px;
    padding: 0.5rem 1rem;
    color: #ffffff;
    /* ShipmentPage button text */
    font-weight: 600;
    transition: transform 0.2s ease;
}

.sort-controls button:hover {
    transform: scale(1.05);
}

.guild-section {
    margin: 3rem 2rem;
}

.guild-section h4 {
    color: #1a202c;
    /* ShipmentPage header text */
    font-size: 1.75rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
}

.badge-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
}

.guild-badge {
    background: rgba(255, 255, 255, 0.9);
    /* ShipmentPage card bg */
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
    /* ShipmentPage shadow */
    text-align: center;
    position: relative;
}

.top-star {
    border: 2px solid #4c51bf;
    /* ShipmentPage accent */
}

.low-star {
    border: 2px solid #dc3545;
    /* ShipmentPage close button (error) */
}

.star-rank {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    /* ShipmentPage button bg */
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: #ffffff;
    /* ShipmentPage button text */
    font-weight: 700;
}

.badge-name {
    font-weight: 700;
    color: #1a202c;
    /* ShipmentPage header text */
    font-size: 1.25rem;
}

.efficiency-bar {
    height: 10px;
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    /* ShipmentPage button bg */
    border-radius: 5px;
    margin: 0.5rem 0;
}

.badge-efficiency,
.badge-output,
.badge-hours {
    color: #718096;
    /* ShipmentPage subtext */
    margin: 0.5rem 0;
}

.leaderboard {
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.leader-card {
    background: rgba(255, 255, 255, 0.9);
    /* ShipmentPage card bg */
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
    /* ShipmentPage shadow */
    text-align: center;
}

.top-leader {
    border: 2px solid #4c51bf;
    /* ShipmentPage accent */
    color: #4c51bf;
    /* ShipmentPage accent */
}

.low-leader {
    border: 2px solid #dc3545;
    /* ShipmentPage close button (error) */
    color: #dc3545;
    /* ShipmentPage close button (error) */
}

.guild-empty {
    text-align: center;
    color: #718096;
    /* ShipmentPage subtext */
    font-size: 1.25rem;
    margin: 2rem;
}