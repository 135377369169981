/* index.css */

:root {
  --primary-color: #5a67d8;
  --secondary-color: #38b2ac;
  --accent-color: #ed8936;
  --sidebar-width: 200px;
  --sidebar-bg: linear-gradient(180deg, #1c62d7 0%, #042361 100%);
  --header-bg: #ffffff;
  --content-bg: #f7fafc;
  --card-bg: #ffffff;
  --text-primary: #1a202c;
  --text-secondary: #718096;
  --text-tertiary: #aee6ed;
  --border-color: #e2e8f0;
  --box-shadow: 1px 2px 6px 15px rgba(0, 0, 0, 0.05);
  --font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

/* Reset & Base Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-family);
  background: var(--content-bg);
  color: var(--text-primary);
  line-height: 1.6;
  font-weight: 400;
  overflow-x: hidden;
  /* Prevent horizontal scroll on body */
}

/* App Layout */
.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
}

/* Sidebar */
.sidebar {
  background: var(--sidebar-bg);
  width: var(--sidebar-width);
  position: fixed;
  height: 100vh;
  padding: 2rem 1rem;
  box-shadow: var(--box-shadow);
  z-index: 1000;
  transition: transform 0.3s ease;
}

.sidebar h4 {
  color: white;
  font-weight: 600;
  margin-bottom: 2rem;
  font-size: 1.5rem;
}

.sidebar .nav-link {
  color: var(--text-tertiary);
  padding: 0.85rem 1rem;
  border-radius: 8px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-weight: 500;
}

.sidebar .nav-link:hover {
  background: rgba(255, 255, 255, 0.1);
  color: white;
}

.sidebar .nav-link.active {
  background: var(--primary-color);
  color: white;
}

/* Main Content */
.main-content {
  margin-left: var(--sidebar-width);
  min-height: 100vh;
  padding: 2rem;
  transition: margin-left 0.3s ease;
  width: calc(100vw - var(--sidebar-width));
  overflow-x: hidden;
}

/* Navbar */
.navbar {
  background: var(--header-bg);
  box-shadow: var(--box-shadow);
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0 0 12px 12px;
  width: 100%;
}

/* Cards */
.card {
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  padding: 2rem;
  background: var(--card-bg);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  width: 100%;
  max-width: 500px;
}

.card:hover {
  transform: translateY(-3px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Tables */
.table {
  background: var(--card-bg);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: var(--box-shadow);
  width: 100%;
  table-layout: auto;
}

.table thead th {
  background: var(--content-bg);
  border-bottom: 2px solid var(--border-color);
  color: var(--text-secondary);
  font-weight: 600;
  padding: 1rem;
  text-transform: uppercase;
  font-size: 0.9rem;
  white-space: nowrap;
}

.table tbody td {
  padding: 1rem;
  vertical-align: middle;
}

.table tbody tr {
  transition: background 0.2s ease;
}

.table tbody tr:hover {
  background: rgba(241, 245, 249, 0.7);
}

/* Summary Table Wrapper */
.summary-table-wrapper {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

/* Buttons */
.btn {
  border-radius: 8px;
  padding: 0.75rem 1.5rem;
  font-weight: 600;
  transition: all 0.2s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.btn-primary {
  background: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}

.btn-primary:hover {
  background: #4c51bf;
  border-color: #4c51bf;
  transform: translateY(-1px);
}

.btn-secondary {
  background: var(--secondary-color);
  border-color: var(--secondary-color);
}

.btn-secondary:hover {
  background: #319795;
  border-color: #319795;
  transform: translateY(-1px);
}

.btn-outline-danger {
  border-color: #e53e3e;
  color: #e53e3e;
}

.btn-outline-danger:hover {
  background: #e53e3e;
  color: white;
}

/* Page Header */
.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
  padding: 1.5rem;
  background: var(--header-bg);
  border-radius: 12px;
  box-shadow: var(--box-shadow);
  width: 100%;
  flex-wrap: wrap;
  gap: 1rem;
}

.page-header h2 {
  margin: 0;
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--text-primary);
}

.controls {
  display: flex;
  gap: 1rem;
}

/* Shipment Table */
.shipment-table {
  margin-top: 2rem;
  width: 100%;
  overflow-x: auto;
}

.shipment-status {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
}

.shipment-status.fulfilled {
  background: #e6fffa;
  color: #2c7a7b;
}

.shipment-status.pending {
  background: #fefcbf;
  color: #975a16;
}

.tracking-link {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 500;
}

.tracking-link:hover {
  text-decoration: underline;
}

/* Modal */
.modal-content {
  border-radius: 20px;
  border: none;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  background: linear-gradient(135deg, #ffffff 0%, #f8fafc 100%);
  max-height: 80vh;
  overflow: scroll;
  width: 100%;
}

.popup-modal .modal-dialog {
  transform: translateY(-10%) !important;
  transition: transform 0.3s ease-in-out;
  max-width: 800px;
  width: 90%;
  margin: 1rem auto;
  margin-left: calc(var(--sidebar-width) + 1rem);
  /* Shift right to avoid sidebar */
}

.popup-modal.show .modal-dialog {
  transform: translateY(0) !important;
}

.modal-zindex-fix {
  z-index: 2000 !important;
  /* Above sidebar */
}

.modal-header {
  border-bottom: none;
  padding: 1.5rem 2rem 0;
  background: transparent;
  position: relative;
}

.modal-title {
  font-weight: 700;
  font-size: 1.5rem;
  color: var(--text-primary);
}

.modal-body {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  height: calc(90vh - 60px);
}

.modal-content-wrapper {
  flex: 1 1 auto;
  overflow-y: auto;
  padding-bottom: 1rem;
}

/* Accordion for Scan History */
.accordion {
  background: transparent;
}

.accordion-item {
  background: var(--card-bg);
  border: none !important;
  border-radius: 12px !important;
  margin-bottom: 1rem;
}

.accordion-header {
  background: var(--card-bg);
  border-radius: 12px !important;
}

.accordion-button {
  background: var(--card-bg) !important;
  color: var(--text-primary) !important;
  font-weight: 600 !important;
  padding: 1rem !important;
  border-radius: 12px !important;
  box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.accordion-body {
  background: var(--card-bg);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 1rem !important;
}

/* Comment Section */
.comment-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Login Page */
.login-container {
  background: linear-gradient(176deg, #2b83db, #c2d0e4);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
}

.login-card {
  background: var(--card-bg);
  border-radius: 20px;
  box-shadow: var(--box-shadow);
  padding: 3rem;
  width: 95%;
  max-width: 450px;
  display: inline-grid;
  text-align: center;
}

/* Forms */
.form-control {
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1rem;
  font-size: 1rem;
  transition: all 0.2s ease;
  width: 100%;
}

.form-control:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 4px rgba(90, 103, 216, 0.1);
}

.form-control-lg {
  padding: 1rem;
  font-size: 1.125rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .sidebar {
    transform: translateX(-100%);
    width: 260px;
    z-index: 2000;
  }

  .sidebar-open {
    transform: translateX(0);
  }

  .main-content {
    margin-left: 0;
    padding: 1rem;
    width: 100vw;
  }

  .navbar {
    padding: 1rem;
    position: relative;
  }

  .page-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .controls {
    width: 100%;
    justify-content: flex-end;
  }

  .modal-content {
    border-radius: 0;
    max-height: 90vh;
  }

  .modal-body {
    padding: 1.5rem;
    height: calc(100vh - 50px);
  }

  .modal-header {
    padding: 1rem 1.5rem 0;
  }

  .modal-title {
    font-size: 1.25rem;
  }

  .popup-modal .modal-dialog {
    transform: none !important;
    width: 100%;
    margin: 0;
    max-width: none;
    margin-left: 0;
    /* Reset shift on mobile */
  }
}

/* Utility Classes */
.shadow-sm {
  box-shadow: var(--box-shadow) !important;
}

.rounded-xl {
  border-radius: 12px !important;
}

.text-muted {
  color: var(--text-secondary) !important;
}

.loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.95);
  z-index: 9999;
  font-size: 1.75rem;
  color: var(--primary-color);
  font-weight: 500;
}

/* index.css or inventory.css */

/* Ensure enabled buttons use your premium gradient */
.btn-primary {
  background: linear-gradient(90deg, var(--primary-color), #4c51bf);
  border: none;
  /* Remove default border if desired */
  transition: opacity 0.2s ease, background 0.2s ease;
}

/* Style for disabled buttons */
.btn-primary:disabled {
  background: #6c757d;
  /* Darker gray for disabled state */
  opacity: 0.7;
  /* Slightly faded */
  color: #fff;
  /* White text for contrast */
  cursor: not-allowed;
  /* Ensure cursor indicates disabled */
  box-shadow: none;
  /* Remove any shadow for flat look */
}

/* Optional: Hover effect for enabled buttons */
.btn-primary:not(:disabled):hover {
  background: linear-gradient(90deg, #4c51bf, var(--primary-color));
  /* Reverse gradient on hover */
  opacity: 0.9;
}