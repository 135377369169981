/* react_frontend/src/pages/HomePage.css */
.cosmic-hub {
    position: relative;
    background: linear-gradient(135deg, #f7fafc 0%, #e2e8f0 100%);
    /* ShipmentPage main bg */
    min-height: 100vh;
    padding: 0;
    overflow-x: hidden;
    color: #1a202c;
    /* ShipmentPage header text */
    font-family: 'Orbitron', sans-serif;
}

.hub-header {
    background: linear-gradient(90deg, #d1d5db, #e5e7eb);
    /* ShipmentPage header bg */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    /* ShipmentPage shadow */
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #e2e8f0;
    /* ShipmentPage border */
    position: sticky;
    top: 0;
    z-index: 1;
    border-radius: 40px;
}

.hub-header h3 {
    color: #1a202c;
    /* ShipmentPage header text */
    font-size: 2.5rem;
    font-weight: 900;
    text-transform: uppercase;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    /* Adjusted for ShipmentPage */
}

.welcome-section {
    margin: 2rem;
    background: rgba(255, 255, 255, 0.9);
    /* ShipmentPage card bg */
    border-radius: 15px;
    padding: 2rem;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
    /* ShipmentPage shadow */
    text-align: center;
}

.welcome-section h4 {
    color: #1a202c;
    /* ShipmentPage header text */
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.welcome-section p {
    color: #718096;
    /* ShipmentPage subtext */
    font-size: 1.2rem;
    line-height: 1.6;
}