/* react_frontend/src/pages/ProductionDashboard.css */
.cosmic-hub {
    background: linear-gradient(135deg, #f7fafc 0%, #e2e8f0 100%);
    min-height: 100vh;
    padding: 0;
    overflow-x: hidden;
}

.hub-header {
    background: linear-gradient(90deg, #d1d5db, #e5e7eb);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    padding: 1.5rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
    flex-wrap: wrap;
    gap: 1rem;
}

.hub-header h3 {
    color: #1a202c;
    font-size: 2.5rem;
    font-weight: 700;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    margin: 0;
}

.header-controls {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.shop-selector,
.date-picker {
    padding: 0.75rem;
    border: 2px solid #e2e8f0;
    border-radius: 10px;
    background: #ffffff;
    font-size: 1rem;
    color: #1a202c;
    min-width: 150px;
}

.shop-selector:focus,
.date-picker:focus {
    border-color: #4c51bf;
    outline: none;
    box-shadow: 0 0 0 4px rgba(76, 81, 191, 0.2);
}

.add-step-toggle {
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    border: none;
    border-radius: 20px;
    padding: 0.75rem 1.5rem;
    color: #ffffff;
    font-weight: 600;
    text-transform: uppercase;
    transition: transform 0.2s ease, background 0.2s ease;
    cursor: pointer;
}

.add-step-toggle:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(76, 81, 191, 0.3);
}

.add-step-toggle.active {
    background: #dc3545;
    /* Red for "Close" state */
}

.star-loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    color: #4c51bf;
    font-size: 1.5rem;
}

.orbit-diviner {
    width: 50px;
    height: 50px;
    border: 4px solid #4c51bf;
    border-radius: 50%;
    animation: divineSpin 1.5s linear infinite;
}

@keyframes divineSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.diviner-error {
    text-align: center;
    color: #dc3545;
    font-size: 1.25rem;
    margin: 2rem;
    font-weight: 500;
}

.dashboard-content {
    padding: 2rem;
}

.dashboard-section {
    margin-bottom: 3rem;
}

.add-step-section {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
}

.dashboard-section h4 {
    color: #1a202c;
    font-size: 1.75rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1.5rem;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.add-step-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.form-field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.form-field label {
    color: #4c51bf;
    font-weight: 600;
    font-size: 1.1rem;
}

.step-input {
    padding: 0.75rem;
    border: 2px solid #e2e8f0;
    border-radius: 10px;
    background: #ffffff;
    color: #1a202c;
    font-size: 1rem;
    transition: border-color 0.2s ease;
}

.step-input:focus {
    border-color: #4c51bf;
    outline: none;
    box-shadow: 0 0 0 4px rgba(76, 81, 191, 0.2);
}

.forge-btn {
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    border: none;
    border-radius: 25px;
    padding: 0.75rem 1.5rem;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
}

.forge-btn:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(76, 81, 191, 0.3);
}

.forge-error {
    color: #dc3545;
    font-size: 1rem;
    text-align: center;
    margin-top: 0.5rem;
    font-weight: 500;
}

.forge-success {
    color: #2c7a7b;
    font-size: 1rem;
    text-align: center;
    margin-top: 0.5rem;
    font-weight: 500;
}

.material-grid,
.production-grid,
.duration-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 2rem;
    padding: 0 1rem;
}

.material-card,
.production-card,
.duration-card,
.shipped-card {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
    text-align: center;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.material-card:hover,
.production-card:hover,
.duration-card:hover,
.shipped-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(76, 81, 191, 0.3);
}

.card-title {
    display: block;
    color: #1a202c;
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}

.card-value {
    display: block;
    color: #718096;
    font-size: 1.1rem;
    font-weight: 500;
}

.card-subvalue {
    display: block;
    color: #718096;
    font-size: 0.95rem;
    margin-top: 0.25rem;
}

.shipped-card {
    max-width: 300px;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .hub-header {
        padding: 1rem;
        flex-direction: column;
        align-items: flex-start;
    }

    .header-controls {
        width: 100%;
        justify-content: center;
    }

    .material-grid,
    .production-grid,
    .duration-grid {
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    }
}

@media (max-width: 576px) {
    .hub-header h3 {
        font-size: 2rem;
    }

    .dashboard-content {
        padding: 1rem;
    }

    .add-step-section {
        padding: 1.5rem;
    }

    .material-grid,
    .production-grid,
    .duration-grid {
        grid-template-columns: 1fr;
    }

    .card-title {
        font-size: 1.1rem;
    }

    .card-value {
        font-size: 1rem;
    }
}