/* react_frontend/src/pages/InventoryPredictionPage.css */
.astral-observatory {
    background: linear-gradient(135deg, #f7fafc 0%, #e2e8f0 100%);
    min-height: 100vh;
    padding: 0;
    overflow-x: hidden;
}

.diviner-lens {
    background: linear-gradient(90deg, #d1d5db, #e5e7eb);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    padding: 2rem;
    text-align: center;
}

.diviner-lens h3 {
    color: #1a202c;
    font-size: 2.5rem;
    font-weight: 700;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    margin-bottom: 1rem;
}

.shop-selector {
    padding: 0.5rem;
    border: 2px solid #e2e8f0;
    border-radius: 8px;
    background: #ffffff;
    font-size: 1rem;
    color: #1a202c;
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
}

.shop-selector:focus {
    border-color: #4c51bf;
    outline: none;
    box-shadow: 0 0 0 4px rgba(76, 81, 191, 0.2);
}

.star-loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    color: #4c51bf;
    font-size: 1.5rem;
}

.orbit-diviner {
    width: 50px;
    height: 50px;
    border: 4px solid #4c51bf;
    border-radius: 50%;
    animation: divineSpin 1.5s linear infinite;
}

@keyframes divineSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.diviner-error {
    text-align: center;
    color: #dc3545;
    font-size: 1.25rem;
    margin: 2rem;
}

.prediction-section {
    margin: 3rem 2rem;
}

.prediction-section h4 {
    color: #1a202c;
    font-size: 1.75rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
}

.no-predictions {
    color: #718096;
    font-size: 1.1rem;
    text-align: center;
    margin: 1rem 0;
}

.warning-grid,
.excess-grid,
.star-chart {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    padding: 0 1rem;
}

.warning-orb,
.excess-cluster,
.material-orbit {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
    transition: transform 0.2s ease;
}

.warning-orb:hover,
.excess-cluster:hover,
.material-orbit:hover {
    transform: translateY(-5px);
}

.warning-orb .orb-glow,
.excess-cluster .cluster-pulse {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, #4c51bf 10%, transparent 70%);
    border-radius: 12px;
    transform: translate(-50%, -50%);
    opacity: 0.3;
}

.cluster-pulse {
    animation: pulseExcess 2s infinite alternate;
}

@keyframes pulseExcess {
    0% {
        opacity: 0.3;
    }

    100% {
        opacity: 0.5;
    }
}

.orb-sku,
.cluster-sku,
.material-name {
    font-weight: 700;
    color: #1a202c;
    font-size: 1.25rem;
    display: block;
    margin-bottom: 0.5rem;
}

.orb-product,
.cluster-product,
.orb-stock,
.cluster-stock,
.material-stock {
    color: #718096;
    margin: 0.5rem 0;
    font-size: 1rem;
}

.orb-timer,
.material-timer {
    color: #dc3545;
    font-weight: 600;
    font-size: 1rem;
}

.cluster-excess {
    color: #2c7a7b;
    font-weight: 600;
    font-size: 1rem;
}

.material-orbit .orbit-ring {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 120%;
    height: 120%;
    background: radial-gradient(circle, #2c7a7b 5%, transparent 70%);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.2;
    animation: orbitRotate 4s linear infinite;
}

@keyframes orbitRotate {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.usage-bar {
    height: 10px;
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    border-radius: 5px;
    margin: 0.5rem auto;
    width: 80%;
}

/* Responsive Adjustments */
@media (max-width: 576px) {
    .diviner-lens {
        padding: 1.5rem;
    }

    .diviner-lens h3 {
        font-size: 2rem;
    }

    .prediction-section {
        margin: 2rem 1rem;
    }

    .prediction-section h4 {
        font-size: 1.5rem;
    }

    .warning-grid,
    .excess-grid,
    .star-chart {
        grid-template-columns: 1fr;
        padding: 0 0.5rem;
    }

    .warning-orb,
    .excess-cluster,
    .material-orbit {
        padding: 1rem;
    }

    .orb-sku,
    .cluster-sku,
    .material-name {
        font-size: 1.1rem;
    }

    .orb-product,
    .cluster-product,
    .orb-stock,
    .cluster-stock,
    .material-stock,
    .orb-timer,
    .cluster-excess,
    .material-timer {
        font-size: 0.9rem;
    }
}


/* Add or update in InventoryPredictionPage.css */
.warning-orb,
.excess-cluster,
.material-orbit {
    position: relative;
    padding: 15px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.1);
    margin: 10px;
    color: #fff;
}

.warning-orb .orb-glow,
.excess-cluster .cluster-pulse {
    width: 10px;
    height: 10px;
    background: #ff4444;
    /* Red for warnings */
    border-radius: 50%;
    position: absolute;
    top: 10px;
    left: 10px;
    animation: pulse 1.5s infinite;
}

.excess-cluster .cluster-pulse {
    background: #ffd700;
    /* Gold for excess */
}

.material-orbit .orbit-ring {
    width: 100%;
    height: 5px;
    background: #524fa1;
    opacity: 0.3;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.usage-bar {
    height: 8px;
    background: linear-gradient(90deg, #524fa1, #00ffcc);
    border-radius: 4px;
    margin: 5px 0;
    transition: width 0.5s ease;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.5);
        opacity: 0.5;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/* Add to InventoryPredictionPage.css */
.diviner-error {
    color: #ff4444;
    padding: 10px;
    text-align: center;
}

.retry-button {
    margin-left: 10px;
    padding: 5px 15px;
    background: #524fa1;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.retry-button:hover {
    background: #00ffcc;
    color: #000;
}

/* Add to InventoryPredictionPage.css */
.cancel-button {
    margin-left: 10px;
    padding: 5px 15px;
    background: #ff4444;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s;
}

.cancel-button:hover {
    background: #cc3333;
}

/* Add to InventoryPredictionPage.css */
.step-tracker {
    margin: 20px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.step {
    padding: 10px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    gap: 10px;
}

.step.pending {
    border: 1px solid #666;
}

.step.running {
    border: 1px solid #00ffcc;
    animation: pulse 1.5s infinite;
}

.step.completed {
    border: 1px solid #00cc00;
}

.step.blocked {
    border: 1px solid #ff4444;
}

.step.error {
    border: 1px solid #ff4444;
}

.step.canceled {
    border: 1px solid #ffd700;
}

.status {
    text-transform: capitalize;
    font-size: 0.9em;
    color: #ccc;
}

.cancel-button {
    padding: 5px 10px;
    background: #ff4444;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.cancel-button:hover {
    background: #cc3333;
}

.restart-button {
    padding: 5px 10px;
    background: #524fa1;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.restart-button:hover {
    background: #00ffcc;
    color: #000;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.05);
        opacity: 0.7;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.controls {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.controls select,
.controls input {
    padding: 0.5rem;
    border: 2px solid #e2e8f0;
    border-radius: 8px;
}

.controls button {
    padding: 0.5rem 1rem;
    background: #4c51bf;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
}

.prediction-table {
    width: 100%;
    border-collapse: collapse;
    margin: 2rem 0;
}

.prediction-table th,
.prediction-table td {
    padding: 1rem;
    border: 1px solid #e2e8f0;
    text-align: left;
}

.prediction-table th {
    background: #4c51bf;
    color: white;
    cursor: pointer;
}

.prediction-table th:hover {
    background: #3b3f9f;
}

.prediction-table tr:nth-child(even) {
    background: #f7fafc;
}