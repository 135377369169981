/* react_frontend/src/layouts/DashboardLayout.css */
.cosmic-spire {
    position: relative;
    background: linear-gradient(135deg, #f7fafc 0%, #e2e8f0 100%);
    min-height: 100vh;
    overflow-x: hidden;
}

.spire-nav {
    background: linear-gradient(270deg, #4c51bf, #111e48);
    position: fixed;
    top: 0;
    left: 0;
    width: 200px;
    height: 100vh;
    transition: width 0.3s ease, transform 0.3s ease;
    z-index: 1000;
    overflow-y: visible;
    /* Needed for desktop submenu */
}

.spire-open {
    width: 200px;
}

.spire-collapsed {
    width: 60px;
}

@media (max-width: 768px) {
    .spire-nav {
        transform: translateX(-100%);
        width: 250px;
        overflow-y: hidden;
        /* Hide overflow on mobile */
    }

    .spire-open {
        transform: translateX(0);
    }

    .spire-collapsed {
        width: 0;
        transform: translateX(-100%);
        overflow: hidden;
        /* Ensure no spillover */
        visibility: hidden;
        /* Fully hide when collapsed */
    }
}

.spire-beacon {
    padding: 1.5rem 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    color: #fff;
}

.spire-beacon h4 {
    color: #171717;
    font-size: 2rem;
    font-weight: 900;
    margin: 0;
    text-shadow: 0px 0px 9px #ffffff;
}

.spire-icon {
    color: #fff;
    font-size: 1.5rem;
    max-width: 20px;
}

.hamburger-toggle {
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    transition: transform 0.2s ease;
    min-width: 15px;
}

.hamburger-toggle:hover {
    transform: scale(1.1);
}

.mobile-toggle {
    margin-right: 1rem;
}

.spire-glyphs {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.glyph-item {
    display: flex;
    flex-direction: column;
    padding: 0.85rem 1rem;
    color: #e8edf6;
    transition: background 0.2s ease;
    text-decoration: none;
    position: relative;
}

.glyph-item:hover {
    background: rgba(241, 245, 249, 0.5);
}

.glyph-header {
    display: flex;
    align-items: center;
    width: 100%;
}

.glyph-icon {
    font-size: 1.5rem;
    margin-right: 1rem;
    color: #eaebf7;
}

.glyph-text {
    font-size: 1rem;
    font-weight: 500;
    color: #fff;
}

.glyph-link {
    color: #fff;
    text-decoration: none;
}

.comet-logout {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(90deg, #dc3545, #e57373);
    border: none;
    border-radius: 20px;
    padding: 0.5rem 1rem;
    color: #ffffff;
    font-weight: 600;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.comet-logout:hover {
    transform: translateX(-50%) scale(1.05);
    box-shadow: 0 6px 12px rgba(220, 53, 69, 0.3);
}

.spire-content {
    margin-left: 200px;
    transition: margin-left 0.3s ease;
}

.spire-nav.spire-collapsed+.spire-content {
    margin-left: 60px;
}

@media (max-width: 768px) {
    .spire-content {
        margin-left: 0;
    }

    .spire-nav.spire-collapsed+.spire-content {
        margin-left: 0;
    }
}

.spire-navbar {
    background: linear-gradient(90deg, #4c51bf, #111e48);
    border-bottom: 1px solid #e2e8f0;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.welcome-orbit {
    display: flex;
    align-items: center;
    color: #e1eafa;
    font-size: 1.25rem;
    font-weight: 500;
    position: relative;
}

.content-vault {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    min-height: calc(100vh - 80px);
}

@media (max-width: 768px) {
    .content-vault {
        padding: 1rem;
    }
}

.submenu {
    display: flex;
    flex-direction: column;
    background: linear-gradient(270deg, #5a61d4, #2a3a7a);
    padding: 0.5rem 0;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.submenu.expanded {
    width: 100%;
}

.submenu.collapsed {
    position: absolute;
    left: 100%;
    width: 180px;
    z-index: 1001;
    display: none;
}

.glyph-item:hover .submenu.collapsed {
    display: flex;
}

/* Hover bridge for desktop only */
@media (min-width: 769px) {
    .glyph-item:hover::after {
        content: '';
        position: absolute;
        top: 0;
        left: 60px;
        width: 20px;
        height: calc(100% + 0.5rem);
        z-index: 1002;
    }
}

.submenu-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    color: #e8edf6;
    text-decoration: none;
    transition: background 0.2s ease;
}

.submenu.expanded .submenu-item {
    padding-left: 2rem;
}

.submenu-item:hover {
    background: rgba(241, 245, 249, 0.5);
}

.submenu .glyph-icon {
    font-size: 1.2rem;
    margin-right: 0.75rem;
}

.submenu .glyph-text {
    font-size: 0.9rem;
}