/* react_frontend/src/pages/ProductionProgressPage.css */

.crimson-forge {
    background: linear-gradient(135deg, #f7fafc 0%, #e2e8f0 100%);
    /* ShipmentPage main bg */
    min-height: 100vh;
    padding: 0;
    overflow-x: hidden;
}

.forge-furnace {
    background: linear-gradient(90deg, #d1d5db, #e5e7eb);
    /* ShipmentPage header bg */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    /* ShipmentPage shadow */
    padding: 2rem;
    text-align: center;
    position: relative;
    border-radius: 40px;
}

.forge-furnace h3 {
    color: #1a202c;
    /* ShipmentPage header text */
    font-size: 2.5rem;
    font-weight: 700;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    /* Adjusted for ShipmentPage */
}

.forge-furnace::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 10px;
    background: linear-gradient(90deg, #4c51bf, transparent);
    /* ShipmentPage accent */
    animation: flameFlicker 2s infinite alternate;
}

@keyframes flameFlicker {
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.forge-loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.9);
    /* ShipmentPage loading bg */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    color: #4c51bf;
    /* ShipmentPage accent */
    font-size: 1.5rem;
}

.steam-spinner {
    width: 50px;
    height: 50px;
    background: radial-gradient(circle, #4c51bf 20%, transparent 70%);
    /* ShipmentPage accent */
    border-radius: 50%;
    animation: steamRise 1.5s ease infinite;
}

@keyframes steamRise {
    0% {
        transform: translateY(0) scale(1);
        opacity: 1;
    }

    100% {
        transform: translateY(-20px) scale(1.2);
        opacity: 0;
    }
}

.forge-error {
    text-align: center;
    color: #dc3545;
    /* ShipmentPage close button (error) */
    font-size: 1.25rem;
    margin: 2rem;
}

.forge-section {
    margin: 3rem 2rem;
}

.forge-section h4 {
    color: #1a202c;
    /* ShipmentPage header text */
    font-size: 1.75rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
}

.station-grid,
.valve-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
}

.station-panel,
.valve-alert {
    background: rgba(255, 255, 255, 0.9);
    /* ShipmentPage card bg */
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
    /* ShipmentPage shadow */
    position: relative;
    text-align: center;
}

.steam-gauge {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, #4c51bf 5%, transparent 70%);
    /* ShipmentPage accent */
    opacity: 0.2;
    border-radius: 12px;
}

.station-name,
.valve-step {
    font-weight: 700;
    color: #1a202c;
    /* ShipmentPage header text */
    font-size: 1.25rem;
}

.progress-bar {
    height: 10px;
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    /* ShipmentPage button bg */
    border-radius: 5px;
    margin: 0.5rem 0;
}

.station-progress,
.station-workers,
.station-units,
.valve-workers,
.valve-suggestion {
    color: #718096;
    /* ShipmentPage subtext */
    margin: 0.5rem 0;
}

.valve-issue {
    color: #975a16;
    /* ShipmentPage pending text */
    font-weight: 600;
}

.valve-pulse {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, #975a16 10%, transparent 70%);
    /* ShipmentPage pending color */
    border-radius: 12px;
    transform: translate(-50%, -50%);
    animation: valveAlert 2s infinite alternate;
}

@keyframes valveAlert {
    0% {
        opacity: 0.3;
    }

    100% {
        opacity: 0.5;
    }
}

.yield-ticker {
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.yield-card {
    background: linear-gradient(135deg, #4c51bf, #7f9cf5);
    /* ShipmentPage button bg */
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
    /* ShipmentPage shadow */
    text-align: center;
    color: #ffffff;
    /* ShipmentPage button text */
}

.yield-value {
    font-size: 2rem;
    font-weight: 700;
    animation: yieldGlow 2s infinite alternate;
}

.forge-empty {
    text-align: center;
    color: #718096;
    /* ShipmentPage subtext */
    font-size: 1.25rem;
    margin: 2rem;
}

@keyframes yieldGlow {
    0% {
        text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
    }

    100% {
        text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
    }
}