@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Playfair+Display:wght@700&display=swap');

.customer-registration-page {
    background: linear-gradient(135deg, #6F40FD 0%, #F1A7C7 100%);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    font-family: 'Inter', sans-serif;
    position: relative;
    overflow: hidden;
}

.particles-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('data:image/svg+xml,<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="1" fill="rgba(255,255,255,0.1)"/></svg>');
    background-size: 3px 3px;
    animation: particles 20s linear infinite;
}

@keyframes particles {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 100px 100px;
    }
}

.container-wrapper {
    width: 100%;
    max-width: 1200px;
    display: flex;
    justify-content: center;
}

.registration-container {
    background: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(20px);
    border-radius: 30px;
    padding: 50px 15px;
    width: 100%;
    max-width: 800px;
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.15);
    position: relative;
}

.exclusive-ribbon {
    position: absolute;
    top: 15px;
    right: -50px;
    background: #FFD700;
    color: #2A2A2A;
    padding: 12px 50px;
    font-weight: 700;
    transform: rotate(45deg);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
    font-size: 0.9rem;
}

.registration-header {
    text-align: center;
    margin-bottom: 40px;
}

.registration-header h2 {
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
    margin-bottom: 15px;
    line-height: 1.1;
}

.gradient-text {
    background: linear-gradient(135deg, #6F40FD 0%, #F1A7C7 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: block;
}

.subtitle {
    font-size: 1.2rem;
    color: #6b7280;
    letter-spacing: 0.5px;
    position: relative;
    display: inline-block;
    padding: 0 25px;
}

.subtitle::before,
.subtitle::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 30px;
    height: 2px;
    background: linear-gradient(90deg, transparent, #6F40FD);
}

.subtitle::before {
    left: -30px;
}

.subtitle::after {
    right: -30px;
    background: linear-gradient(90deg, #6F40FD, transparent);
}

.floating-label {
    position: relative;
}

.input-icon {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    color: #6F40FD;
    font-size: 1.2rem;
}

.form-control {
    border-radius: 12px;
    padding: 16px 20px 16px 50px !important;
    font-size: 1rem;
    background: rgba(244, 244, 244, 0.5);
    border: 2px solid rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.form-control:focus {
    background: rgba(255, 255, 255, 0.9);
    border-color: #6F40FD;
    box-shadow: 0 0 0 3px rgba(111, 64, 253, 0.2);
}

.phone-input {
    display: flex;
    gap: 12px;
    align-items: center;
}

.country-code-select {
    flex: 0 0 130px;
    padding: 16px 20px !important;
    border-radius: 12px !important;
    /*background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 15px center;*/
    background-size: 14px;
    max-width: fit-content;
}

.phone-number-input {
    flex: 1;
    min-width: 0;
}

.btn-register {
    background: linear-gradient(135deg, #6F40FD, #8B4BFF);
    border: none;
    border-radius: 12px;
    padding: 18px;
    font-size: 1.1rem;
    font-weight: 600;
    transition: all 0.3s ease;
    width: 100%;
    margin-top: 25px;
    position: relative;
    overflow: hidden;
}

.btn-register::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(45deg, transparent, rgba(255, 255, 255, 0.15), transparent);
    transform: rotate(45deg);
    animation: shine 3s infinite;
}

@keyframes shine {
    0% {
        transform: translateX(-100%) rotate(45deg);
    }

    100% {
        transform: translateX(100%) rotate(45deg);
    }
}

.spinner {
    display: inline-block;
    width: 1.25rem;
    height: 1.25rem;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s linear infinite;
    margin-right: 8px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.success-message,
.error-message {
    padding: 16px;
    border-radius: 12px;
    margin-top: 25px;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 0.95rem;
}

.success-message {
    background: #d1fae5;
    color: #065f46;
}

.error-message {
    background: #fee2e2;
    color: #991b1b;
}

.status-icon {
    font-size: 1.4rem;
    flex-shrink: 0;
}