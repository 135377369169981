/* react_frontend/src/pages/SettingsPage.css */
.celestial-forge {
    position: relative;
    background: linear-gradient(135deg, #f7fafc 0%, #e2e8f0 100%);
    min-height: 100vh;
    padding: 0;
    overflow-x: hidden;
    color: #1a202c;
    font-family: 'Orbitron', sans-serif;
}

.forge-header {
    background: linear-gradient(90deg, #d1d5db, #e5e7eb);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #e2e8f0;
    position: sticky;
    top: 0;
    z-index: 1;
    border-radius: 40px;
}

.forge-header h3 {
    color: #1a202c;
    font-size: 2.5rem;
    font-weight: 900;
    text-transform: uppercase;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.forge-section {
    margin: 2rem;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 15px;
    padding: 2rem;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
}

.forge-section h4 {
    color: #4c51bf;
    font-size: 1.8rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
}

.identity-plaque .plaque-content {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.forge-name {
    font-size: 1.5rem;
    font-weight: 600;
    color: #1a202c;
}

.orbiting-star {
    width: 20px;
    height: 20px;
    background: #4c51bf;
    border-radius: 50%;
    animation: orbit 2s infinite linear;
}

@keyframes orbit {
    0% {
        transform: rotate(0deg) translateX(10px) rotate(0deg);
    }

    100% {
        transform: rotate(360deg) translateX(10px) rotate(-360deg);
    }
}

.color-crucible .crucible-content {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
}

.molten-color {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.molten-color label {
    color: #718096;
    font-weight: 500;
}

.molten-color input {
    border: none;
    padding: 0;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    cursor: pointer;
}

.stellar-forge .forge-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.orbiting-realms {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.realm-badge {
    background: #ffffff;
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-width: 200px;
}

.realm-badge span {
    color: #1a202c;
    font-weight: 500;
}

.status-ring {
    padding: 0.5rem 1rem;
    border-radius: 20px;
    text-align: center;
    font-weight: 600;
}

.status-ring.active {
    background: #e6fffa;
    color: #2c7a7b;
}

.status-ring.inactive {
    background: #fefcbf;
    color: #975a16;
}

.forge-date {
    color: #718096;
    font-size: 0.9rem;
}

.forge-portal {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 400px;
}

.forge-portal label {
    color: #4c51bf;
    font-weight: 600;
}

.forge-portal input,
.forge-portal select {
    padding: 0.8rem;
    border: 2px solid #e2e8f0;
    border-radius: 10px;
    background: #ffffff;
    color: #1a202c;
    font-size: 1rem;
}

.forge-portal select {
    appearance: none;
}

.forge-error {
    color: #dc3545;
    font-weight: 500;
}

.forge-success {
    color: #2c7a7b;
    font-weight: 500;
}

.forge-btn {
    background: linear-gradient(90deg, #4c51bf, #7f9cf5);
    border: none;
    border-radius: 25px;
    padding: 0.8rem 1.5rem;
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
    transition: transform 0.3s ease;
}

.forge-btn:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(76, 81, 191, 0.3);
}

.forge-btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.forge-hint {
    color: #718096;
    font-size: 0.9rem;
}

.forge-hint a {
    color: #4c51bf;
    text-decoration: none;
}

.forge-hint a:hover {
    text-decoration: underline;
}

/* Enhanced Crew Forge Styles */
.crew-forge .forge-portal {
    max-width: 500px;
}

.crew-forge input,
.crew-forge select {
    margin-bottom: 1rem;
}

.crew-list {
    margin-bottom: 2rem;
    background: #ffffff;
    /* ShipmentPage card bg */
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
    /* ShipmentPage shadow */
}

.crew-list h5 {
    color: #4c51bf;
    /* ShipmentPage accent */
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
    text-align: center;
}

.crew-table {
    width: 100%;
    border-collapse: collapse;
}

.crew-table-header {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    /* Name, Email, Role proportions */
    gap: 1rem;
    padding: 1rem;
    background: rgba(241, 245, 249, 0.5);
    /* ShipmentPage hover bg */
    border-bottom: 2px solid #e2e8f0;
    /* ShipmentPage border */
    font-weight: 600;
    color: #1a202c;
    /* ShipmentPage header text */
    text-transform: uppercase;
}

.crew-table-row {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    gap: 1rem;
    padding: 1rem;
    border-bottom: 1px solid #e2e8f0;
    /* ShipmentPage border */
    transition: background 0.2s ease, transform 0.2s ease;
}

.crew-table-row:hover {
    background: rgba(241, 245, 249, 0.7);
    /* Slightly darker hover bg */
    transform: translateY(-2px);
}

.crew-table-row span {
    color: #1a202c;
    /* ShipmentPage header text */
    font-weight: 500;
    font-size: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.crew-table-row .crew-role {
    color: #2c7a7b;
    /* ShipmentPage fulfilled text */
    font-weight: 700;
    background: #e6fffa;
    /* ShipmentPage fulfilled bg */
    padding: 0.5rem 1rem;
    border-radius: 20px;
    text-transform: uppercase;
    text-align: center;
}

/* Mobile Optimization */
@media (max-width: 768px) {

    .crew-table-header,
    .crew-table-row {
        grid-template-columns: 1fr;
        /* Stack columns on mobile */
        gap: 0.5rem;
        padding: 0.75rem;
    }

    .crew-table-row span {
        font-size: 0.9rem;
        padding: 0.5rem 0;
    }

    .crew-table-row .crew-role {
        padding: 0.4rem 0.8rem;
        font-size: 0.85rem;
    }
}

@media (max-width: 576px) {
    .crew-list {
        padding: 1rem;
    }

    .crew-list h5 {
        font-size: 1.25rem;
        margin-bottom: 1rem;
    }
}